import { useMemo } from 'react'
import Deployments from '@8pay/deployments'
import useAuth from './useAuth'

const useDeployments = () => {
  const { chain } = useAuth()

  const deployments = useMemo(() => (chain ? new Deployments(chain) : null), [chain])

  return deployments
}

export default useDeployments
