// ** React Imports
import { useCallback } from 'react'

// ** Hooks Imports
import { useNavigate } from 'react-router-dom'

// ** Utility Imports
import { ResourceType } from '../ResourceType'
import { NotificationType, NotificationEvent } from '../Notification'

import useClickHandler from './useClickHandler'

const Direction = {
  IN: 0,
  OUT: 1
}

const useNotificationHandler = () => {
  const { handleClick } = useClickHandler()
  const navigate = useNavigate()

  const getDirection = notification => {
    switch (notification.event) {
      case NotificationEvent.PLAN_CREATED:
      case NotificationEvent.BILLING_RESULTS:
      case NotificationEvent.TERMINATE_SUBSCRIPTIONS_RESULTS:
        return Direction.IN

      case NotificationEvent.SUBSCRIPTION:
      case NotificationEvent.BILLING:
      case NotificationEvent.BILLING_FAILED:
      case NotificationEvent.SUBSCRIPTION_CANCELLED:
      case NotificationEvent.SUBSCRIPTION_TERMINATED:
      case NotificationEvent.ALLOWANCE_CHANGED:
        return Direction.OUT
    }
  }

  const handleOneTime = useCallback(notification => handleClick(ResourceType.ONE_TIME_PAYMENT, notification.entity), [handleClick])

  const handleFixedRecurring = useCallback(notification => {
    const direction = getDirection(notification)

    if (direction === Direction.IN) {
      handleClick(ResourceType.FIXED_RECURRING_PLAN, notification.entity)
    } else {
      handleClick(ResourceType.FIXED_RECURRING_SUBSCRIPTION, notification.subEntity)
    }
  }, [handleClick])

  const handleVariableRecurring = useCallback(notification => {
    const direction = getDirection(notification)

    if (direction === Direction.IN) {
      handleClick(ResourceType.VARIABLE_RECURRING_PLAN, notification.entity)
    } else {
      handleClick(ResourceType.VARIABLE_RECURRING_SUBSCRIPTION, notification.subEntity)
    }
  }, [handleClick])

  const handleOnDemand = useCallback(notification => {
    const direction = getDirection(notification)

    if (direction === Direction.IN) {
      handleClick(ResourceType.ON_DEMAND_PLAN, notification.entity)
    } else {
      handleClick(ResourceType.ON_DEMAND_SUBSCRIPTION, notification.subEntity)
    }
  }, [handleClick])

  const handleAutobiller = useCallback(notification => {
    if (!notification.subEntity) {
      navigate('/wallet')
    } else {
      handleClick(ResourceType.FIXED_RECURRING_SUBSCRIPTION, notification.subEntity)
    }
  }, [navigate, handleClick])

  const handleDollarCostAverage = useCallback(notification => {
    if (notification.event === NotificationEvent.DOLLAR_COST_AVERAGE_INSUFFICIENT_GAS_WALLET_BALANCE) {
      navigate('/wallet')
    } else {
      handleClick(ResourceType.STRATEGY, notification.entity)
    }
  }, [navigate, handleClick])

  const handleWalletToWallet = useCallback(notification => handleClick(ResourceType.WALLET_TO_WALLET_PAYMENT, notification.entity), [handleClick])

  const handleNotificationClick = useCallback((notification) => {
    switch (notification.type) {
      case NotificationType.ONE_TIME:
        return handleOneTime(notification)

      case NotificationType.FIXED_RECURRING:
        return handleFixedRecurring(notification)

      case NotificationType.VARIABLE_RECURRING:
        return handleVariableRecurring(notification)

      case NotificationType.ON_DEMAND:
        return handleOnDemand(notification)

      case NotificationType.AUTOBILLER:
        return handleAutobiller(notification)

      case NotificationType.DOLLAR_COST_AVERAGE:
      case NotificationType.DOLLAR_COST_AVERAGE_EXECUTOR:
        return handleDollarCostAverage(notification)

      case NotificationType.WALLET_TO_WALLET:
        return handleWalletToWallet(notification)

      default:
        throw new Error('Unhandled notification type')
    }
  }, [
    handleOneTime,
    handleFixedRecurring,
    handleVariableRecurring,
    handleOnDemand,
    handleAutobiller,
    handleDollarCostAverage,
    handleWalletToWallet
  ])

  return { handleNotificationClick }
}

export default useNotificationHandler
