import { useEffect, useState, useCallback, createContext } from 'react'
import { v4 as uuidv4 } from 'uuid'
import useAuth from '../hooks/useAuth'
import useExchangeRates from '../hooks/useExchangeRates'

const MAX_RANGE = 6 * 30 * 24 * 60 * 60

const UsdRates = createContext()

const UsdRatesProvider = ({ children }) => {
  const { loggedIn } = useAuth()
  const { api: { history } } = useExchangeRates()
  const [from, setFrom] = useState(Math.floor(Date.now() / 1000) - MAX_RANGE)
  const [rates, setRates] = useState([])
  const [queue, setQueue] = useState([])

  const mergeRates = (lessRecentRates, mostRecentRates) => {
    const mergedRates = []

    for (let i = 0; i < mostRecentRates.length; i++) {
      mergedRates.push({
        token: mostRecentRates[i].token,
        rates: [...lessRecentRates[i].rates, ...mostRecentRates[i].rates]
      })
    }

    return mergedRates
  }

  const findRate = useCallback((token, timestamp) => {
    const tokenRates = rates.find(e => e.token === token).rates
    const length = tokenRates.length

    if (timestamp >= tokenRates[length - 1][1]) {
      return tokenRates[length - 1][0]
    }

    const firstTimestamp = tokenRates[0][1]
    const lastTimestamp = tokenRates[length - 1][1]
    const interval = (lastTimestamp - firstTimestamp) / (length - 1)
    const start = tokenRates[0][1]
    const index = Math.floor((timestamp - start) / interval)

    return tokenRates[index][0]
  }, [rates])

  const getRate = useCallback((token, timestamp) => new Promise(resolve => {
    setQueue(prev => [...prev, { id: uuidv4(), args: { token, timestamp }, resolver: value => resolve(value) }])
  }), [])

  useEffect(() => {
    if (rates.length && queue.length) {
      const request = queue[0]
      const oldestRateTimestamp = rates[0].rates[0][1]

      if (request.args.timestamp < oldestRateTimestamp) {
        return setFrom(prev => (request.args.timestamp < prev ? prev - MAX_RANGE : prev))
      }

      const rate = findRate(request.args.token, request.args.timestamp)

      request.resolver(rate)

      setQueue(prev => prev.filter(e => e.id !== request.id))
    }
  }, [rates, queue, findRate])

  useEffect(() => {
    if (loggedIn) {
      history(from, from + MAX_RANGE).then(({ data }) => {
        setRates(prev => (prev.length ? mergeRates(data.data, prev) : data.data))
      })
    }
  }, [from, history, loggedIn])

  return <UsdRates.Provider value={{ getRate }}>{children}</UsdRates.Provider>
}

export { UsdRates, UsdRatesProvider }
