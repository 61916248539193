import { useState, useEffect, useCallback, useMemo } from 'react'
import axios from 'axios'
import useApiUrlBuilder from './useApiUrlBuilder'
import { variableRecurringEndpoints } from '../../constants/endpoints'
import useDeployments from './useDeployments'
import useAuth from './useAuth'

const useVariableRecurring = () => {
  const { account } = useAuth()
  const [plansContract, setPlansContract] = useState()
  const [subscriptionsContract, setSubscriptionsContract] = useState()
  const deployments = useDeployments()
  const buildApiUrl = useApiUrlBuilder()

  useEffect(() => {
    if (deployments) {
      setPlansContract(deployments.get('VariableRecurringPlans'))
      setSubscriptionsContract(deployments.get('VariableRecurringSubscriptions'))
    }
  }, [deployments])

  const getPlan = useCallback(planId => {
    return axios.get(buildApiUrl(variableRecurringEndpoints.plans.resource, { planId }))
  }, [buildApiUrl])

  const listPlans = useCallback(params => {
    return axios.get(buildApiUrl(variableRecurringEndpoints.plans.collection), { params })
  }, [buildApiUrl])

  const getSubscription = useCallback(subscriptionId => {
    return axios.get(buildApiUrl(variableRecurringEndpoints.subscriptions.resource, { subscriptionId }))
  }, [buildApiUrl])

  const listSubscriptions = useCallback(params => {
    return axios.get(buildApiUrl(variableRecurringEndpoints.subscriptions.collection), { params })
  }, [buildApiUrl])

  const listSubscriptionsByPlan = useCallback((planId, params) => {
    return axios.get(buildApiUrl(variableRecurringEndpoints.plans.subscriptions, { planId }), { params })
  }, [buildApiUrl])

  const listBillingsByPlan = useCallback((planId, params) => {
    return axios.get(buildApiUrl(variableRecurringEndpoints.plans.billings, { planId }), { params })
  }, [buildApiUrl])

  const listBillingsBySubscription = useCallback((subscriptionId, params) => {
    return axios.get(buildApiUrl(variableRecurringEndpoints.subscriptions.billings, { subscriptionId }), { params })
  }, [buildApiUrl])

  const createShortUrl = useCallback(data => {
    return axios.post(buildApiUrl(variableRecurringEndpoints.shortUrls.collection), data)
  }, [buildApiUrl])

  const listShortUrlsByPlan = useCallback((planId, params) => {
    return axios.get(buildApiUrl(variableRecurringEndpoints.plans.shortUrls, { planId }), { params })
  }, [buildApiUrl])

  const deleteShortUrl = useCallback(path => {
    return axios.delete(buildApiUrl(variableRecurringEndpoints.shortUrls.resource, { path }))
  }, [buildApiUrl])

  const getWebhook = useCallback(planId => {
    return axios.get(buildApiUrl(variableRecurringEndpoints.plans.webhook, { planId }))
  }, [buildApiUrl])

  const setWebhook = useCallback((planId, webhook) => {
    return axios.put(buildApiUrl(variableRecurringEndpoints.plans.webhook, { planId }), { webhook })
  }, [buildApiUrl])

  const deleteWebhook = useCallback(planId => {
    return axios.delete(buildApiUrl(variableRecurringEndpoints.plans.webhook, { planId }))
  }, [buildApiUrl])

  const listTransfers = useCallback(params => {
    return axios.get(buildApiUrl(variableRecurringEndpoints.transfers.collection), { params })
  }, [buildApiUrl])

  const createPlan = useCallback((name, maxAmount, token, period, receiver, category) => ({
    address: plansContract.address,
    abi: plansContract.abi,
    functionName: 'createPlan',
    args: [name, maxAmount, token, period, receiver, category],
    from: account
  }), [account, plansContract])

  const requestSubscriptionCancellation = useCallback(subscriptionId => ({
    address: subscriptionsContract.address,
    abi: subscriptionsContract.abi,
    functionName: 'requestCancellation',
    args: [subscriptionId],
    from: account
  }), [account, subscriptionsContract])

  const variableRecurring = useMemo(() => ({
    api: {
      getPlan,
      listPlans,
      getSubscription,
      listSubscriptions,
      listSubscriptionsByPlan,
      listBillingsByPlan,
      listBillingsBySubscription,
      createShortUrl,
      listShortUrlsByPlan,
      deleteShortUrl,
      getWebhook,
      setWebhook,
      deleteWebhook,
      listTransfers
    },
    blockchain: {
      createPlan,
      requestSubscriptionCancellation
    }
  }), [
    getPlan,
    listPlans,
    getSubscription,
    listSubscriptions,
    listSubscriptionsByPlan,
    listBillingsByPlan,
    listBillingsBySubscription,
    createShortUrl,
    listShortUrlsByPlan,
    deleteShortUrl,
    getWebhook,
    setWebhook,
    deleteWebhook,
    listTransfers,
    createPlan,
    requestSubscriptionCancellation
  ])

  return variableRecurring
}

export default useVariableRecurring
