import { useCallback, useMemo } from 'react'
import axios from 'axios'
import useApiUrlBuilder from './useApiUrlBuilder'
import { walletToWalletEndpoints } from '../../constants/endpoints'

const useWalletToWallet = () => {
  const buildApiUrl = useApiUrlBuilder()

  const getPayment = useCallback(id => {
    return axios.get(buildApiUrl(walletToWalletEndpoints.payments.resource, { id }))
  }, [buildApiUrl])

  const listTransfers = useCallback(params => {
    return axios.get(buildApiUrl(walletToWalletEndpoints.transfers.collection), { params })
  }, [buildApiUrl])

  const walletToWallet = useMemo(() => ({
    api: {
      getPayment,
      listTransfers
    }
  }), [getPayment, listTransfers])

  return walletToWallet
}

export default useWalletToWallet
