import { BillingModel } from './BillingModel'

export const PaymentType = {
  ONE_TIME: 0,
  SUBSCRIPTION: 1,
  SCHEDULED: 2
}

export const PaymentTypeName = {
  [PaymentType.ONE_TIME]: 'One-time Payment',
  [PaymentType.SUBSCRIPTION]: 'Subscription',
  [PaymentType.SCHEDULED]: 'Scheduled Payment'
}

export const billingModelToPaymentTypeName = {
  [BillingModel.ONE_TIME]: PaymentTypeName[PaymentType.ONE_TIME],
  [BillingModel.WALLET_TO_WALLET]: PaymentTypeName[PaymentType.ONE_TIME],
  [BillingModel.FIXED_RECURRING]: PaymentTypeName[PaymentType.SUBSCRIPTION],
  [BillingModel.VARIABLE_RECURRING]: PaymentTypeName[PaymentType.SUBSCRIPTION],
  [BillingModel.ON_DEMAND]: PaymentTypeName[PaymentType.SUBSCRIPTION],
  [BillingModel.SCHEDULED_PAYMENTS]: PaymentTypeName[PaymentType.SCHEDULED]
}

export const paymentTypeToBillingModels = {
  [PaymentType.ONE_TIME]: [BillingModel.ONE_TIME, BillingModel.WALLET_TO_WALLET],
  [PaymentType.SUBSCRIPTION]: [BillingModel.FIXED_RECURRING, BillingModel.VARIABLE_RECURRING, BillingModel.ON_DEMAND],
  [PaymentType.SCHEDULED]: [BillingModel.SCHEDULED_PAYMENTS]
}

