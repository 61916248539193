import { configureChains, createConfig } from 'wagmi'
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { LedgerConnector } from 'wagmi/connectors/ledger'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { publicProvider } from 'wagmi/providers/public'
import { WagmiChain } from '../utility/WagmiChain'

const walletConnectProjectId = '7df90edcfa886b837cc587c6826b82c3'

const defaultChains = Object.values(WagmiChain)

const { chains, publicClient, webSocketPublicClient } = configureChains(defaultChains, [publicProvider()])

export const config = createConfig({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    new WalletConnectConnector({
      chains: chains,
      options: {
        projectId: walletConnectProjectId
      }
    }),
    new CoinbaseWalletConnector({
      chains: chains,
      options: {
        appName: '8Pay'
      }
    }),
    new LedgerConnector({ chains }),
    new InjectedConnector({
      chains: chains,
      options: {
        name: 'Injected',
        shimDisconnect: true
      }
    })
  ],
  publicClient: publicClient,
  webSocketPublicClient: webSocketPublicClient
})

