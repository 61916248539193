export default {
  chains: {
    private: {
      label: 'Private',
      config: {
        chainId: '0x61',
        chainName: 'Binance Smart Chain Testnet',
        nativeCurrency: {
          name: 'Binance Coin',
          symbol: 'tBNB',
          decimals: 18
        },
        rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545'],
        blockExplorerUrls: ['https://testnet.bscscan.com']
      },
      contracts: {
        eightPayTokenV2Claims: '0xAa94D41207Cdb7db1eFDC7e35d3058Ba656C881E'
      },
      gasWallet: {
        token: "8PAY"
      },
      tiers: {
        token: "8PAY"
      }
    }
  },
  domains: {
    api: 'https://api.8pay.tech',
    auth: 'https://auth.8pay.tech',
    websocket: 'https://websocket.8pay.tech',
    checkout: 'https://checkout.8pay.tech',
    shopify: 'https://shopify.8pay.tech',
    rates: 'https://rates.8pay.tech',
    streamingDonations: 'https://streaming-donations.8pay.tech',
    fcm: 'https://fcm.8pay.tech',
    claims: 'https://claims.8pay.network',
    webPush: 'https://web-push.8pay.tech'
  },
  legacyAppUrl: 'https://app-v1.8pay.tech',
  pwaUrl: 'https://m.8pay.tech/app',
  pushServer: {
    publicKey: 'BE041Wp5_Aokls9uveoXR46TzQknCNFU-SuywliWtZGKgWRp-zMNpcKt8truA4ywDNmNdS7-2GEs4hXxadP4Zak'
  }
}
