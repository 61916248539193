import { useContext } from 'react'
import { RightSidebar } from '../context/RightSidebar'

const useRightSidebar = () => {
  const { openSidebar, closeSidebar  } = useContext(RightSidebar)

  return { openSidebar, closeSidebar  }
}

export default useRightSidebar
