// ** React Imports
import { Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// ** Third Party Components
import classnames from 'classnames'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Button } from 'reactstrap'

// ** Vertical Menu Components
import VerticalNavMenuItems from './VerticalNavMenuItems'
import VerticalMenuHeader from './VerticalMenuHeader'
import ThemeToggler from './ThemeToggler'

const Sidebar = props => {
  // ** Props
  const { menuCollapsed, skin, menuData } = props

  // ** States
  const [groupOpen, setGroupOpen] = useState([])
  const [groupActive, setGroupActive] = useState([])
  const [currentActiveGroup, setCurrentActiveGroup] = useState([])
  const [activeItem, setActiveItem] = useState(null)

  // ** Menu Hover State
  const [menuHover, setMenuHover] = useState(false)

  const navigate = useNavigate()

  // ** Function to handle Mouse Enter
  const onMouseEnter = () => {
    setMenuHover(true)
  }

  return (
    <Fragment>
      <div
        className={classnames('main-menu menu-fixed menu-accordion menu-shadow', {
          expanded: menuHover || menuCollapsed === false,
          'menu-light': skin !== 'semi-dark' && skin !== 'dark',
          'menu-dark': skin === 'semi-dark' || skin === 'dark'
        })}
        onMouseEnter={onMouseEnter}
        onMouseLeave={() => setMenuHover(false)}
      >
        <Fragment>
          <VerticalMenuHeader />

          <PerfectScrollbar
            className='main-menu-content mt-3 scrollbar-none'
            options={{ wheelPropagation: false }}
          >
            <ul className='navigation navigation-main d-flex flex-column'>
              <VerticalNavMenuItems
                items={menuData}
                menuData={menuData}
                menuHover={menuHover}
                groupOpen={groupOpen}
                activeItem={activeItem}
                groupActive={groupActive}
                setGroupOpen={setGroupOpen}
                menuCollapsed={menuCollapsed}
                setActiveItem={setActiveItem}
                setGroupActive={setGroupActive}
                currentActiveGroup={currentActiveGroup}
                setCurrentActiveGroup={setCurrentActiveGroup}
              />
            </ul>

            <div className='d-flex flex-column footer'>
              <div className='d-block mx-2 py-2 mt-auto'>
                <div className='d-flex'>
                  <ThemeToggler/>
                </div>

                <Button.Ripple className="mt-2 round" color='primary' outline block onClick={() => navigate('/logout')}>
                  Logout
                </Button.Ripple>
              </div>
            </div>
          </PerfectScrollbar>
        </Fragment>
      </div>
    </Fragment>
  )
}

export default Sidebar
