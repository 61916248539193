// ** Environments
import environment from '@configs/environments'

const apiUrl = `${environment.domains.api}/v1/:chain`
const authUrl = `${environment.domains.auth}/v1`
const shopifyUrl = `${environment.domains.shopify}/api/v1`
const ratesUrl = `${environment.domains.rates}/api/v1`
const streamingDonationsUrl = `${environment.domains.streamingDonations}/api/v1`
const fcmUrl = `${environment.domains.fcm}/api/v1`
const claimsUrl = `${environment.domains.claims}`
const webPushUrl = `${environment.domains.webPush}/api/v1`

export const authEndpoints = {
  authorize: `${authUrl}/authorize`,
  revoke: `${authUrl}/revoke`
}

export const apikeyEndpoints = {
  collection: `${authUrl}/api-keys`,
  resource: `${authUrl}/api-keys/:key`
}

export const oneTimeEndpoints = {
  payments: {
    collection: `${apiUrl}/one-time`,
    resource: `${apiUrl}/one-time/:id`
  },
  shortUrls: {
    collection: `${apiUrl}/one-time/short-urls`
  },
  transfers: {
    collection: `${apiUrl}/one-time/transfers`
  }
}

export const fixedRecurringEndpoints = {
  plans: {
    resource: `${apiUrl}/fixed-recurring/plans/:planId`,
    collection: `${apiUrl}/fixed-recurring/plans`,
    subscriptions: `${apiUrl}/fixed-recurring/plans/:planId/subscriptions`,
    billings: `${apiUrl}/fixed-recurring/plans/:planId/billings`,
    webhook: `${apiUrl}/fixed-recurring/plans/:planId/webhook`,
    shortUrls: `${apiUrl}/fixed-recurring/plans/:planId/short-urls`
  },
  subscriptions: {
    collection: `${apiUrl}/fixed-recurring/subscriptions`,
    resource: `${apiUrl}/fixed-recurring/subscriptions/:subscriptionId`,
    billings: `${apiUrl}/fixed-recurring/subscriptions/:subscriptionId/billings`
  },
  transfers: {
    collection: `${apiUrl}/fixed-recurring/transfers`
  },
  shortUrls: {
    collection: `${apiUrl}/fixed-recurring/short-urls`,
    resource: `${apiUrl}/fixed-recurring/short-urls/:path`
  }
}

export const variableRecurringEndpoints = {
  plans: {
    resource: `${apiUrl}/variable-recurring/plans/:planId`,
    collection: `${apiUrl}/variable-recurring/plans`,
    subscriptions: `${apiUrl}/variable-recurring/plans/:planId/subscriptions`,
    billings: `${apiUrl}/variable-recurring/plans/:planId/billings`,
    webhook: `${apiUrl}/variable-recurring/plans/:planId/webhook`,
    shortUrls: `${apiUrl}/variable-recurring/plans/:planId/short-urls`
  },
  subscriptions: {
    collection: `${apiUrl}/variable-recurring/subscriptions`,
    resource: `${apiUrl}/variable-recurring/subscriptions/:subscriptionId`,
    billings: `${apiUrl}/variable-recurring/subscriptions/:subscriptionId/billings`
  },
  transfers: {
    collection: `${apiUrl}/variable-recurring/transfers`
  },
  shortUrls: {
    collection: `${apiUrl}/variable-recurring/short-urls`,
    resource: `${apiUrl}/variable-recurring/short-urls/:path`
  }
}

export const onDemandEndpoints = {
  plans: {
    resource: `${apiUrl}/on-demand/plans/:planId`,
    collection: `${apiUrl}/on-demand/plans`,
    subscriptions: `${apiUrl}/on-demand/plans/:planId/subscriptions`,
    billings: `${apiUrl}/on-demand/plans/:planId/billings`,
    webhook: `${apiUrl}/on-demand/plans/:planId/webhook`,
    shortUrls: `${apiUrl}/on-demand/plans/:planId/short-urls`
  },
  subscriptions: {
    collection: `${apiUrl}/on-demand/subscriptions`,
    resource: `${apiUrl}/on-demand/subscriptions/:subscriptionId`,
    billings: `${apiUrl}/on-demand/subscriptions/:subscriptionId/billings`
  },
  transfers: {
    collection: `${apiUrl}/on-demand/transfers`
  },
  shortUrls: {
    collection: `${apiUrl}/on-demand/short-urls`,
    resource: `${apiUrl}/on-demand/short-urls/:path`
  }
}

export const scheduledPaymentsEndpoints = {
  schedules: {
    collection: `${apiUrl}/scheduled-payments/schedules`,
    resource: `${apiUrl}/scheduled-payments/schedules/:scheduleId`,
    executions: `${apiUrl}/scheduled-payments/schedules/:scheduleId/executions`
  },
  transfers: {
    collection: `${apiUrl}/scheduled-payments/transfers`
  }
}

export const dollarCostAverageEndpoints = {
  strategies: {
    collection: `${apiUrl}/dollar-cost-average/strategies`,
    resource: `${apiUrl}/dollar-cost-average/strategies/:strategyId`,
    executions: `${apiUrl}/dollar-cost-average/strategies/:strategyId/executions`
  }
}

export const walletToWalletEndpoints = {
  payments: {
    resource: `${apiUrl}/wallet-to-wallet/payments/:id`
  },
  transfers: {
    collection: `${apiUrl}/wallet-to-wallet/transfers`
  }
}

export const shopifyEndpoints = {
  stores: {
    collection: `${shopifyUrl}/stores`,
    resource: `${shopifyUrl}/stores/:domain`
  }
}

export const ratesEndpoints = {
  history: `${ratesUrl}/history`
}

export const transactionsEndpoints = {
  resource: `${apiUrl}/transactions/:transactionHash`
}

export const streamingDonationsEndpoints = {
  channels: {
    collection: `${streamingDonationsUrl}/channels`,
    resource: `${streamingDonationsUrl}/channels/:channelId`,
    test: `${streamingDonationsUrl}/channels/:channelId/test`
  },
  images: {
    collection: `${streamingDonationsUrl}/images`
  },
  donations: {
    collection: `${streamingDonationsUrl}/donations`
  },
  blacklist: {
    resource: `${streamingDonationsUrl}/blacklist`
  }
}

export const notificationsEndpoints = {
  collection: `${apiUrl}/notifications`,
  read: `${apiUrl}/notifications/:notificationId/read`
}

export const autobillerEndpoints = {
  plans: {
    collection: `${apiUrl}/autobiller/plans`,
    resource: `${apiUrl}/autobiller/plans/:planId`
  }
}

export const gasWalletEndpoints = {
  operations: {
    collection: `${apiUrl}/gas-wallet/operations`
  }
}

export const fcmEndpoints = {
  tokens: {
    collection: `${fcmUrl}/tokens`,
    resource: `${fcmUrl}/tokens/:token`
  }
}

export const claimsEndpoints = {
  resource: `${claimsUrl}/v2/:chainId/:address`
}

export const webPushEndpoints = {
  subscriptions: {
    collection: `${webPushUrl}/subscriptions`
  }
}
