import { Fragment, useState, useEffect } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Badge, DropdownMenu, DropdownItem, DropdownToggle, Dropdown } from 'reactstrap'
import { useSearchParams } from 'react-router-dom'
import { Bell, Circle } from 'react-feather'
import useAuth from '@hooks/useAuth'
import useWebsocket from '@hooks/useWebsocket'
import useNotifications from '@hooks/useNotifications'
import useNotificationClickHandler from '@hooks/useNotificationClickHandler'
import useRightSidebar from '@hooks/useRightSidebar'
import { formatDateTime } from '@utils'

const NotificationDropdown = () => {
  const { loggedIn } = useAuth()
  const { websocket, isReady } = useWebsocket()
  const { api: { listNotifications, readNotification } } = useNotifications()
  const { handleNotificationClick } = useNotificationClickHandler()
  const { closeSidebar } = useRightSidebar()
  const [searchParams] = useSearchParams()
  const [isOpen, setIsOpen] = useState(['1', 'true'].includes(searchParams.get('showNotifications')))
  const [notifications, setNotifications] = useState([])
  const [showUnread, setShowUnread] = useState(true)

  const getUnreadNotificationsCount = () => notifications.filter(notification => notification.unread).length

  const toggle = () => {
    closeSidebar()
    setIsOpen(prev => !prev)
  }

  const onDropdownToggle = () => {
    const unreadNotifications = notifications.filter(notification => notification.unread)

    setTimeout(() => {
      setShowUnread(false)

      Promise.all(unreadNotifications.map(notification => readNotification(notification.id)))
        .then(() => setNotifications(prev => prev.map(notification => ({ ...notification, unread: false }))))
    }, 2000)
  }

  const onNotificationClick = notification => {
    setIsOpen(false)
    handleNotificationClick(notification)
  }

  useEffect(() => {
    window.actions.showNotifications = () => setIsOpen(true)
  }, [])

  useEffect(() => {
    if (loggedIn) {
      listNotifications({ limit: 30 }).then(res => setNotifications(res.data.data))
    }
  }, [loggedIn, listNotifications])

  useEffect(() => {
    if (!isReady) {
      return
    }

    websocket.on('notification', notification => {
      setNotifications(prev => [notification, ...prev])
      setShowUnread(true)
    })

    return () => {
      websocket.off('notification')
    }
  }, [isReady, websocket])

  const Notification = ({ item, onClick  }) => {
    return (
      <a className='d-flex' onClick={onClick}>
        <div className='list-item d-flex align-items-center'>
          <Fragment>
            <div>
              <div className='fw-bold mb-50'>{item.message}</div>
              <div><small>{formatDateTime(item.timestamp * 1000)}</small></div>
            </div>

            <div className='ms-auto me-25 ps-2'>
              {showUnread && item.unread && (
                <Circle className='rounded-circle text-primary bg-primary' size={10} />
              )}
            </div>
          </Fragment>
        </div>
      </a>
    )
  }

  return (
    <Dropdown isOpen={isOpen} toggle={toggle} tag='li' className='dropdown-notification nav-item me-25'>
      <DropdownToggle tag='a' className='nav-link bg-transparent' onClick={onDropdownToggle}>
        <Bell size={21} />

        {showUnread && getUnreadNotificationsCount() > 0 && (
          <Badge pill color='danger' className='badge-up'>
            {getUnreadNotificationsCount()}
          </Badge>
        )}
      </DropdownToggle>

      <DropdownMenu end tag='ul' className='dropdown-menu-media mt-0'>
        <li className='dropdown-menu-header'>
          <DropdownItem className='d-flex' tag='div' header>
            <h4 className='notification-title mb-0 me-auto'>Notifications</h4>
            <Badge tag='div' color='light-primary' pill>
              {showUnread ? getUnreadNotificationsCount() : 0} New
            </Badge>
          </DropdownItem>
        </li>

        <PerfectScrollbar component='li' className='media-list scrollable-container' options={{ wheelPropagation: false }}>
          {notifications.length > 0 ? (
            notifications.map((item, index) => <Notification key={index} item={item} onClick={() => onNotificationClick(item)}/>)
          ) : (
            <p className="text-center fs-5 p-2">
              No notifications to show
            </p>
          )}
        </PerfectScrollbar>
      </DropdownMenu>
    </Dropdown>
  )
}

export default NotificationDropdown
