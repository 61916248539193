// ** Icons Import
import { Home, Key, CreditCard, RefreshCw, Grid, Video, ShoppingBag, DollarSign } from 'react-feather'

export default [
  {
    id: 'home',
    title: 'Home',
    icon: <Home />,
    navLink: '/home'
  },
  {
    id: 'wallet',
    title: 'Wallet',
    icon: <CreditCard />,
    navLink: '/wallet'
  },
  {
    id: 'payments',
    title: 'Payments',
    icon: <RefreshCw />,
    navLink: '/payments'
  },
  {
    id: 'more',
    title: 'More',
    icon: <Grid />,
    children: [
      {
        id: 'invest',
        title: 'Invest',
        icon: <DollarSign />,
        navLink: '/invest'
      },
      {
        id: 'streaming',
        title: 'Streaming',
        icon: <Video />,
        navLink: '/streaming'
      },
      {
        id: 'shopify',
        title: 'Shopify',
        icon: <ShoppingBag />,
        navLink: '/shopify'
      },
      {
        id: 'api-keys',
        title: 'API Keys',
        icon: <Key />,
        navLink: '/api-keys'
      }
    ]
  }
]
