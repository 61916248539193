// ** React Imports
import { lazy } from 'react'

const Home = lazy(() => import('../../views/home'))

const HomeRoutes = [
  {
    element: <Home />,
    path: '/home'
  }
]

export default HomeRoutes
