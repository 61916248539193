export const NotificationType = {
  ONE_TIME: 'one-time',
  FIXED_RECURRING: 'fixed-recurring',
  VARIABLE_RECURRING: 'variable-recurring',
  ON_DEMAND: 'on-demand',
  AUTOBILLER: 'autobiller',
  DOLLAR_COST_AVERAGE: 'dollar-cost-average',
  DOLLAR_COST_AVERAGE_EXECUTOR: 'dollar-cost-average-executor',
  WALLET_TO_WALLET: 'wallet-to-wallet'
}

export const NotificationEvent = {
  PAYMENT: 'Payment',
  PLAN_CREATED: 'PlanCreated',
  SUBSCRIPTION: 'Subscription',
  BILLING: 'Billing',
  BILLING_FAILED: 'BillingFailed',
  BILLING_RESULTS: 'BillingResults',
  SUBSCRIPTION_CANCELLED: 'SubscriptionCancelled',
  SUBSCRIPTION_TERMINATED: 'SubscriptionTerminated',
  TERMINATE_SUBSCRIPTIONS_RESULTS: 'TerminateSubscriptionResults',
  PERMISSION_GRANTED: 'PermissionGranted',
  PERMISSION_GRANT_RESULTS: 'PermissionGrantResults',
  PERMISSION_REVOKED: 'PermissionRevoked',
  PERMISSION_REVOKE_RESULTS: 'PermissionRevokeResults',
  RECEIVER_CHANGED: 'ReceiverChanged',
  ALLOWANCE_CHANGED: 'AllowanceChanged',
  DOLLAR_COST_AVERAGE_INSUFFICIENT_GAS_WALLET_BALANCE: 'DollarCostAverageInsufficientGasWalletBalance'
}
