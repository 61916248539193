import { Badge } from "reactstrap"
import { SubscriptionStatus } from "../../utility/SubscriptionStatus"

const SubscriptionStatusColor = {
  [SubscriptionStatus.ACTIVE]: 'light-success',
  [SubscriptionStatus.CANCELLED]: 'light-danger',
  [SubscriptionStatus.TERMINATED]: 'light-danger',
  [SubscriptionStatus.EXPIRED]: 'light-warning',
  [SubscriptionStatus.CANCELLATION_REQUESTED]: 'light-warning'
}

const SubscriptionStatusBadge = ({ status, className }) => {
  return (
    <Badge className={`text-capitalize ${className}`} color={SubscriptionStatusColor[status]} pill>
      {status.replace('_', ' ').toLowerCase()}
    </Badge>
  )
}

export default SubscriptionStatusBadge
