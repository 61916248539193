// ** Vertical Menu Components
import VerticalNavMenuLink from '@layouts/components/menu/vertical-menu/VerticalNavMenuLink'
import VerticalNavMenuGroup from '@layouts/components/menu/vertical-menu/VerticalNavMenuGroup'
import VerticalNavMenuSectionHeader from '@layouts/components/menu/vertical-menu/VerticalNavMenuSectionHeader'

// ** Utils
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@layouts/utils'

const VerticalMenuNavItems = props => {
  // ** Components Object
  const Components = {
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
    VerticalNavMenuSectionHeader
  }

  // ** Render Nav Menu Items
  const RenderNavItems = props.items.map((item, index) => {
    const TagName = Components[resolveNavItemComponent(item)]

    if (item.children) {
      return <TagName item={item} index={index} key={item.id} {...props} />
    }

    return <TagName key={item.id || item.header} item={item} {...props} />
  })

  return RenderNavItems
}

export default VerticalMenuNavItems
