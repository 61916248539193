import { useContext } from 'react'
import { Modal } from '../context/Modal'

const useModal = () => {
  const { openModal, closeModal } = useContext(Modal)

  return { openModal, closeModal }
}

export default useModal
