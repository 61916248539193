import { useCallback, useEffect, useMemo, useState, createContext } from 'react'
import * as Tokens from '@8pay/tokens'
import * as Deployments from '@8pay/deployments'
import useAuth from '../hooks/useAuth'
import { forceResolvePromise } from '../Utils'
import ERC20_ABI from '../../constants/abi/erc20.json'
import { NATIVE_TOKEN_ADDRESS } from '../../constants/addresses'
import { readContract } from '@wagmi/core'
import environment from '../../configs/environments'

const Allowances = createContext()

const initialState = {
  Transfers: {},
  GasWallet: {},
  TiersStaking: {}
}

const AllowancesProvider = ({ children }) => {
  const { user, chain, loggedIn } = useAuth()
  const [allowances, setAllowances] = useState(initialState)

  const tokens = useMemo(() => (chain ? new Tokens(chain) : null), [chain])
  const deployments = useMemo(() => (chain ? new Deployments(chain) : null), [chain])

  const update = (contract, token, allowance) => setAllowances(prev => ({
    ...prev,
    [contract]: {
      ...prev[contract],
      [token]: allowance
    }
  }))

  const loadTransfers = useCallback(async () => {
    const erc20Tokens = tokens.all().filter(e => e.address !== NATIVE_TOKEN_ADDRESS)

    const reads = erc20Tokens.map(e => readContract({
      address: e.address,
      abi: ERC20_ABI,
      functionName: 'allowance',
      args: [user, deployments.get('Transfers').address]
    }))

    const erc20TokenAllowances = await Promise.all(reads.map(e => forceResolvePromise(e)))
    const allowances = erc20Tokens.reduce((obj, token, index) => ({ ...obj, [token.symbol]: erc20TokenAllowances[index].toString() }), {})
    setAllowances(prev => ({ ...prev, Transfers: allowances }))
  }, [user, deployments, tokens])

  const loadGasWallet = useCallback(async () => {
    const token = environment.chains[chain].gasWallet.token

    const allowance = await forceResolvePromise(readContract({
      address: tokens.get(token).address,
      abi: ERC20_ABI,
      functionName: 'allowance',
      args: [user, deployments.get('GasWallet').address]
    }))
    setAllowances(prev => ({ ...prev, GasWallet: { [token]: allowance.toString() } }))
  }, [user, deployments, tokens, chain])

  const loadTiersStaking = useCallback(async () => {
    const token = environment.chains[chain].tiers.token

    const allowance = await forceResolvePromise(readContract({
      address: tokens.get(token).address,
      abi: ERC20_ABI,
      functionName: 'allowance',
      args: [user, deployments.get('TiersStaking').address]
    }))
    setAllowances(prev => ({ ...prev, TiersStaking: { [token]: allowance.toString() } }))
  }, [user, deployments, tokens, chain])

  useEffect(() => {
    if (loggedIn && user && deployments && tokens) {
      loadTransfers()
      loadGasWallet()
      loadTiersStaking()
    }
  }, [user, deployments, loadTransfers, loadGasWallet, loadTiersStaking, tokens, loggedIn])

  return <Allowances.Provider value={{ allowances, update }}>{children}</Allowances.Provider>
}

export { Allowances, AllowancesProvider }
