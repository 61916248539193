export const BillingModel = {
  ONE_TIME: 0,
  FIXED_RECURRING: 1,
  VARIABLE_RECURRING: 2,
  ON_DEMAND: 3,
  SCHEDULED_PAYMENTS: 4,
  WALLET_TO_WALLET: 5
}

export const BillingModelName = {
  [BillingModel.ONE_TIME]: 'One-time',
  [BillingModel.FIXED_RECURRING]: 'Fixed Recurring',
  [BillingModel.VARIABLE_RECURRING]: 'Variable Recurring',
  [BillingModel.ON_DEMAND]: 'On Demand',
  [BillingModel.SCHEDULED_PAYMENTS]: 'Scheduled Payment',
  [BillingModel.WALLET_TO_WALLET]: 'Wallet-to-wallet'
}

export const getAmountPrefixByBillingModel = billingModel => {
  if (billingModel === BillingModel.VARIABLE_RECURRING || billingModel === BillingModel.ON_DEMAND) {
    return 'MAX '
  }

  return ''
}

export const getAmountFieldByBillingModel = billingModel => {
  if (billingModel === BillingModel.FIXED_RECURRING) {
    return 'amount'
  } else if (billingModel === BillingModel.VARIABLE_RECURRING) {
    return 'maxAmount'
  } else if (billingModel === BillingModel.ON_DEMAND) {
    return 'minAllowance'
  }
}
