import { useMemo } from 'react'
import Tokens from '@8pay/tokens'
import useAuth from './useAuth'

const useTokens = () => {
  const { chain } = useAuth()

  const tokens = useMemo(() => (chain ? new Tokens(chain) : null), [chain])

  return tokens
}

export default useTokens
