import { useState, useCallback, useEffect, useMemo } from 'react'
import axios from 'axios'
import useApiUrlBuilder from './useApiUrlBuilder'
import { onDemandEndpoints } from '../../constants/endpoints'
import useDeployments from './useDeployments'
import useAuth from './useAuth'

const useOnDemand = () => {
  const { account } = useAuth()
  const [plansContract, setPlansContract] = useState()
  const [subscriptionsContract, setSubscriptionsContract] = useState()
  const deployments = useDeployments()
  const buildApiUrl = useApiUrlBuilder()

  useEffect(() => {
    if (deployments) {
      setPlansContract(deployments.get('OnDemandPlans'))
      setSubscriptionsContract(deployments.get('OnDemandSubscriptions'))
    }
  }, [deployments])

  const getPlan = useCallback(planId => {
    return axios.get(buildApiUrl(onDemandEndpoints.plans.resource, { planId }))
  }, [buildApiUrl])

  const listPlans = useCallback(params => {
    return axios.get(buildApiUrl(onDemandEndpoints.plans.collection), { params })
  }, [buildApiUrl])

  const getSubscription = useCallback(subscriptionId => {
    return axios.get(buildApiUrl(onDemandEndpoints.subscriptions.resource, { subscriptionId }))
  }, [buildApiUrl])

  const listSubscriptions = useCallback(params => {
    return axios.get(buildApiUrl(onDemandEndpoints.subscriptions.collection), { params })
  }, [buildApiUrl])

  const listSubscriptionsByPlan = useCallback((planId, params) => {
    return axios.get(buildApiUrl(onDemandEndpoints.plans.subscriptions, { planId }), { params })
  }, [buildApiUrl])

  const listBillingsByPlan = useCallback((planId, params) => {
    return axios.get(buildApiUrl(onDemandEndpoints.plans.billings, { planId }), { params })
  }, [buildApiUrl])

  const listBillingsBySubscription = useCallback((subscriptionId, params) => {
    return axios.get(buildApiUrl(onDemandEndpoints.subscriptions.billings, { subscriptionId }), { params })
  }, [buildApiUrl])

  const createShortUrl = useCallback(data => {
    return axios.post(buildApiUrl(onDemandEndpoints.shortUrls.collection), data)
  }, [buildApiUrl])

  const listShortUrlsByPlan = useCallback((planId, params) => {
    return axios.get(buildApiUrl(onDemandEndpoints.plans.shortUrls, { planId }), { params })
  }, [buildApiUrl])

  const deleteShortUrl = useCallback(path => {
    return axios.delete(buildApiUrl(onDemandEndpoints.shortUrls.resource, { path }))
  }, [buildApiUrl])

  const getWebhook = useCallback(planId => {
    return axios.get(buildApiUrl(onDemandEndpoints.plans.webhook, { planId }))
  }, [buildApiUrl])

  const setWebhook = useCallback((planId, webhook) => {
    return axios.put(buildApiUrl(onDemandEndpoints.plans.webhook, { planId }), { webhook })
  }, [buildApiUrl])

  const deleteWebhook = useCallback(planId => {
    return axios.delete(buildApiUrl(onDemandEndpoints.plans.webhook, { planId }))
  }, [buildApiUrl])

  const listTransfers = useCallback(params => {
    return axios.get(buildApiUrl(onDemandEndpoints.transfers.collection), { params })
  }, [buildApiUrl])

  const createPlan = useCallback((name, minAllowance, token, period, receiver, category) => ({
    address: plansContract.address,
    abi: plansContract.abi,
    functionName: 'createPlan',
    args: [name, minAllowance, token, period, receiver, category],
    from: account
  }), [account, plansContract])

  const cancelSubscription = useCallback(subscriptionId => ({
    address: subscriptionsContract.address,
    abi: subscriptionsContract.abi,
    functionName: 'cancel',
    args: [subscriptionId],
    from: account
  }), [account, subscriptionsContract])

  const onDemand = useMemo(() => ({
    api: {
      getPlan,
      listPlans,
      getSubscription,
      listSubscriptions,
      listSubscriptionsByPlan,
      listBillingsByPlan,
      listBillingsBySubscription,
      createShortUrl,
      listShortUrlsByPlan,
      deleteShortUrl,
      getWebhook,
      setWebhook,
      deleteWebhook,
      listTransfers
    },
    blockchain: {
      createPlan,
      cancelSubscription
    }
  }), [
    getPlan,
    listPlans,
    getSubscription,
    listSubscriptions,
    listSubscriptionsByPlan,
    listBillingsByPlan,
    listBillingsBySubscription,
    createShortUrl,
    listShortUrlsByPlan,
    deleteShortUrl,
    getWebhook,
    setWebhook,
    deleteWebhook,
    listTransfers,
    createPlan,
    cancelSubscription
  ])

  return onDemand
}

export default useOnDemand
