// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

// ** Configs
import config from '../configs/auth'

const storageKeys = Object.keys(config.storageKeys)
const initialState = storageKeys.reduce((state, key) => ({ ...state, [key]: localStorage.getItem(key) }), {})

export const authSlice = createSlice({
  name: 'authentication',
  initialState: initialState,
  reducers: {
    handleLogin: (state, action) => {
      state[config.storageKeys.user] = action.payload.user
      state[config.storageKeys.chain] = action.payload.chain
      state[config.storageKeys.connector] = action.payload.connector
      state[config.storageKeys.accessToken] = action.payload.token
      state[config.storageKeys.accessTokenExpiresAt] = action.payload.expiresAt
      state[config.storageKeys.accessTokenIssuedAt] = action.payload.issuedAt

      localStorage.setItem(config.storageKeys.user, action.payload.user)
      localStorage.setItem(config.storageKeys.chain, action.payload.chain)
      localStorage.setItem(config.storageKeys.connector, action.payload.connector)
      localStorage.setItem(config.storageKeys.accessToken, action.payload.token)
      localStorage.setItem(config.storageKeys.accessTokenExpiresAt, action.payload.expiresAt)
      localStorage.setItem(config.storageKeys.accessTokenIssuedAt, action.payload.issuedAt)
    },
    handleLogout: state => {
      state[config.storageKeys.user] = null
      state[config.storageKeys.chain] = null
      state[config.storageKeys.connector] = null
      state[config.storageKeys.accessToken] = null
      state[config.storageKeys.accessTokenExpiresAt] = null
      state[config.storageKeys.accessTokenIssuedAt] = null

      localStorage.removeItem(config.storageKeys.user)
      localStorage.removeItem(config.storageKeys.chain)
      localStorage.removeItem(config.storageKeys.connector)
      localStorage.removeItem(config.storageKeys.accessToken)
      localStorage.removeItem(config.storageKeys.accessTokenExpiresAt)
      localStorage.removeItem(config.storageKeys.accessTokenIssuedAt)
    },
    handleRefresh: (state, action) => {
      state[config.storageKeys.accessToken] = action.payload.token
      state[config.storageKeys.accessTokenExpiresAt] = action.payload.expiresAt
      state[config.storageKeys.accessTokenIssuedAt] = action.payload.issuedAt

      localStorage.setItem(config.storageKeys.accessToken, action.payload.token)
      localStorage.setItem(config.storageKeys.accessTokenExpiresAt, action.payload.expiresAt)
      localStorage.setItem(config.storageKeys.accessTokenIssuedAt, action.payload.issuedAt)
    }
  }
})

export const { handleLogin, handleLogout, handleRefresh } = authSlice.actions

export default authSlice.reducer
