import { useCallback } from 'react'
import useAuth from './useAuth'

const useApiUrlBuilder = () => {
  const { chain } = useAuth()

  const build = useCallback((url, fields = {}) => {
    let finalUrl = url.replaceAll(/:chain(?=\/|$)/g, chain)
    for (const key in fields) {
      finalUrl = finalUrl.replaceAll(new RegExp(`:${key}(?=\/|$)`, 'g'), fields[key])
    }
    return finalUrl
  }, [chain])

  return build
}

export default useApiUrlBuilder
