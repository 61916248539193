// ** React Imports
import { Link } from 'react-router-dom'

// ** Reactstrap Imports
import { Navbar, NavItem } from 'reactstrap'

// ** Horizontal Menu Components
import NotificationDropdown from '../common/NotificationDropdown'
import UserDropdown from './UserDropdown'
import HorizontalNavMenuItems from './HorizontalNavMenuItems'

// ** Custom Hooks
import { useSkin } from '@hooks/useSkin'
import { useNavbarColor } from '@hooks/useNavbarColor'

// ** Menu Items Array
import navigation from '@src/navigation/horizontal'

// ** Images
import logo from '@src/assets/images/logo.svg'
import logoFullWhite from '@src/assets/images/logo-full-white.png'

const HorizontalNavbar = () => {
  // ** Hooks
  const { skin } = useSkin()
  const { navbarColor } = useNavbarColor()

  const bgColorCondition = navbarColor !== '' && navbarColor !== 'light' && navbarColor !== 'white'

  return (
    <Navbar
      tag='div'
      expand='sm'
      light={skin !== 'dark'}
      dark={skin === 'dark' || bgColorCondition}
      className='header-navbar navbar-horizontal navbar-shadow menu-border fixed-top'
    >
      <div className='navbar-header'>
        <ul className='nav navbar-nav'>
          <NavItem>
            <Link to='/' className='navbar-brand'>
              <span className='brand-logo'>
                <img src={skin === 'dark' ? logoFullWhite : logo} alt='logo' />
              </span>
            </Link>
          </NavItem>
        </ul>
      </div>

      <div className='navbar-container d-flex'>
        <ul className='nav navbar-nav ms-auto' id='main-menu-navigation'>
          <HorizontalNavMenuItems submenu={false} items={navigation} />

          <ul className='nav navbar-nav align-items-center'>
            <UserDropdown />
            <NotificationDropdown />
          </ul>
        </ul>
      </div>
    </Navbar>
  )
}

export default HorizontalNavbar
