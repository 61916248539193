// ** Custom Components
import Avatar from '@components/avatar'
import CopyToClipboard from '@src/components/copy-to-clipboard'

// ** Utils
import { generateUniqueName } from '@utils'
import ChainIcon from '@src/utility/ChainIcon'

// ** Third Party Components
import useTiers from '@hooks/useTiers'
import useAuth from '@hooks/useAuth'

const UserInfo = () => {
  const { chain, user, loggedIn } = useAuth()
  const { currentTier } = useTiers()

  return (
    <div className='user-info d-flex'>
      <Avatar className="me-1 h-100" img={ChainIcon[chain]} imgHeight='48' imgWidth='48' />
      <div className='user-nav'>
        <span className='user-name fw-bold d-block'>{generateUniqueName(user)}</span>
        <div className='user-data d-flex align-items-center'>
          <Avatar className="tier-logo" img={currentTier.image} imgHeight='20' imgWidth='20' />
          <span className='tier'>{currentTier.name}</span>
          {loggedIn && <span className='address'>{user.slice(0, 4)}...{user.slice(user.length - 7)}</span>}
          <CopyToClipboard text={user} size={18} />
        </div>
      </div>
    </div>
  )
}

export default UserInfo
