import { PaymentType } from './PaymentType'
import { BillingModel, BillingModelName } from './BillingModel'
import { SubscriptionStatus } from './SubscriptionStatus'

export const DateFilter = {
  THIS_MONTH: 0,
  LAST_MONTH: 1,
  LAST_90_DAYS: 2,
  THIS_YEAR: 3,
  LAST_YEAR: 4
}

export const dateFilterOptions = [
  { label: 'This month', value: DateFilter.THIS_MONTH },
  { label: 'Last month', value: DateFilter.LAST_MONTH },
  { label: 'Last 90 days', value: DateFilter.LAST_90_DAYS },
  { label: 'This year', value: DateFilter.THIS_YEAR },
  { label: 'Last year', value: DateFilter.LAST_YEAR }
]

export const getDateRange = dateFilter => {
  const d = new Date()

  const year = d.getUTCFullYear()
  const month = d.getUTCMonth()
  const day = d.getUTCDate()

  let start, end

  switch (dateFilter) {
    case DateFilter.THIS_MONTH:
      start = new Date(year, month, 1)
      end = new Date(year, month + 1, 0, 23, 59, 59)
      break

    case DateFilter.LAST_MONTH:
      start = new Date(year, month - 1, 1)
      end = new Date(year, month, 0, 23, 59, 59)
      break

    case DateFilter.LAST_90_DAYS:
      start = new Date(year, month, day - 90)
      end = new Date(year, month, day, 23, 59, 59)
      break

    case DateFilter.THIS_YEAR:
      start = new Date(year, 0, 1)
      end = new Date(year, 11, 31, 23, 59, 59)
      break

    case DateFilter.LAST_YEAR:
      start = new Date(year - 1, 0, 1)
      end = new Date(year - 1, 11, 31, 23, 59, 59)
      break
  }

  return {
    from: start.getTime() / 1000,
    to: end.getTime() / 1000
  }
}

export const paymentTypeFilterOptions = [
  { label: 'All payments', value: null },
  { label: 'One-time payments', value: PaymentType.ONE_TIME },
  { label: 'Scheduled payments', value: PaymentType.SCHEDULED },
  { label: 'Subscriptions', value: PaymentType.SUBSCRIPTION }
]

export const planTypeFilterOptions = [
  { label: 'All types', value: null },
  { label: BillingModelName[BillingModel.FIXED_RECURRING], value: BillingModel.FIXED_RECURRING },
  { label: BillingModelName[BillingModel.VARIABLE_RECURRING], value: BillingModel.VARIABLE_RECURRING },
  { label: BillingModelName[BillingModel.ON_DEMAND], value: BillingModel.ON_DEMAND }
]

export const ScheduledPaymentStatus = {
  ACTIVE: 'ACTIVE',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED'
}

export const scheduledPaymentStatusOptions = [
  { label: 'All statuses', value: null },
  { label: 'Active', value: ScheduledPaymentStatus.ACTIVE },
  { label: 'Completed', value: ScheduledPaymentStatus.COMPLETED },
  { label: 'Cancelled', value: ScheduledPaymentStatus.CANCELLED }
]

export const subscriptionStatusFilterOptions = [
  { label: 'All statuses', value: null },
  { label: 'Active', value: SubscriptionStatus.ACTIVE },
  { label: 'Expired', value: SubscriptionStatus.EXPIRED },
  { label: 'Cancellation Requested', value: SubscriptionStatus.CANCELLATION_REQUESTED },
  { label: 'Cancelled', value: SubscriptionStatus.CANCELLED },
  { label: 'Terminated', value: SubscriptionStatus.TERMINATED }
]

export const subscriptionStatusFilterOptionsPerBillingModel = {
  [BillingModel.FIXED_RECURRING]: [
    { label: 'All statuses', value: null },
    { label: 'Active', value: SubscriptionStatus.ACTIVE },
    { label: 'Expired', value: SubscriptionStatus.EXPIRED },
    { label: 'Cancelled', value: SubscriptionStatus.CANCELLED },
    { label: 'Terminated', value: SubscriptionStatus.TERMINATED }
  ],
  [BillingModel.VARIABLE_RECURRING]: [
    { label: 'All statuses', value: null },
    { label: 'Active', value: SubscriptionStatus.ACTIVE },
    { label: 'Expired', value: SubscriptionStatus.EXPIRED },
    { label: 'Cancellation Requested', value: SubscriptionStatus.CANCELLATION_REQUESTED },
    { label: 'Cancelled', value: SubscriptionStatus.CANCELLED },
    { label: 'Terminated', value: SubscriptionStatus.TERMINATED }
  ],
  [BillingModel.ON_DEMAND]: [
    { label: 'All statuses', value: null },
    { label: 'Active', value: SubscriptionStatus.ACTIVE },
    { label: 'Cancelled', value: SubscriptionStatus.CANCELLED },
    { label: 'Terminated', value: SubscriptionStatus.TERMINATED }
  ]
}

export const StrategyStatus = {
  ACTIVE: 'ACTIVE',
  CANCELLED: 'CANCELLED'
}

export const strategyStatusOptions = [
  { label: 'All statuses', value: null },
  { label: 'Active', value: StrategyStatus.ACTIVE },
  { label: 'Cancelled', value: StrategyStatus.CANCELLED }
]
