import { useCallback, useEffect, useMemo, useState, createContext } from 'react'
import * as Tokens from '@8pay/tokens'
import useAuth from '../hooks/useAuth'
import { forceResolvePromise } from '../Utils'
import ERC20_ABI from '../../constants/abi/erc20.json'
import { NATIVE_TOKEN_ADDRESS } from '../../constants/addresses'
import { fetchBalance, readContract } from '@wagmi/core'

const Balances = createContext()

const BalancesProvider = ({ children }) => {
  const { user, chain, loggedIn } = useAuth()
  const [balances, setBalances] = useState({})
  const [rawBalances, setRawBalances] = useState({})
  const [isLoading, setIsLoading] = useState()
  const [initialized, setInitialized] = useState(false)

  const tokens = useMemo(() => (chain ? new Tokens(chain) : null), [chain])

  const loadBalances = useCallback(async () => {
    const tokenList = tokens.all()

    const nativeToken = tokenList.find(e => e.address === NATIVE_TOKEN_ADDRESS)
    const nativeTokenBalance = await fetchBalance({ address: user })

    const erc20Tokens = tokenList.filter(e => e.address !== NATIVE_TOKEN_ADDRESS)

    const reads = erc20Tokens.map(e => readContract({
      address: e.address,
      abi: ERC20_ABI,
      functionName: 'balanceOf',
      args: [user]
    }))

    const erc20TokenBalances = await Promise.all(reads.map(e => forceResolvePromise(e)))

    const balances = { [nativeToken.symbol]: nativeTokenBalance.formatted }
    const rawBalances = { [nativeToken.symbol]: nativeTokenBalance.value.toString() }

    erc20Tokens.forEach((token, index) => {
      balances[token.symbol] = tokens.formatAmount(erc20TokenBalances[index], token.address)
      rawBalances[token.symbol] = erc20TokenBalances[index]
    })

    setBalances(balances)
    setRawBalances(rawBalances)
  }, [user, tokens])

  useEffect(() => {
    if (tokens && !initialized) {
      const initialValue = tokens.all().reduce((obj, token) => ({ ...obj, [token.symbol]: 0 }), {})
      setBalances(initialValue)
      setRawBalances(initialValue)
    }
  }, [isLoading, tokens, initialized])

  useEffect(() => {
    if (loggedIn && tokens && !initialized) {
      loadBalances().then(() => setIsLoading(false))
      setIsLoading(true)
      setInitialized(true)
    }
  }, [loggedIn, loadBalances, tokens, initialized])

  useEffect(() => {
    if (initialized && !loggedIn) {
      setBalances({})
      setRawBalances({})
      setInitialized(false)
    }
  }, [initialized, loggedIn])

  const refresh = useCallback(() => loadBalances(), [loadBalances])

  return <Balances.Provider value={{ balances, rawBalances, refresh }}>{children}</Balances.Provider>
}

export { Balances, BalancesProvider }
