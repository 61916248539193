// ** React Imports
import { Link } from 'react-router-dom'

// ** Custom Hooks
import { useSkin } from '@hooks/useSkin'
import useAuth from '@hooks/useAuth'

// ** Custom Components
import Avatar from '@components/avatar'
import UserInfo from '../common/UserInfo'
import ChainIcon from '@src/utility/ChainIcon'

// ** Utils
import { generateUniqueName } from '@utils'
import environment from '@configs/environments'

// ** Reactstrap Imports
import { UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap'

const UserDropdown = () => {
  const { chain, user } = useAuth()
  const { skin, setSkin } = useSkin()

  return (
    <UncontrolledDropdown tag='li' className='dropdown-user nav-item'>
      <DropdownToggle href='/' tag='a' className='nav-link dropdown-user-link' onClick={e => e.preventDefault()}>
        <Avatar img={ChainIcon[chain]} imgHeight='28' imgWidth='28' />
        <div className='user-nav'>
          <span className='user-name mb-0 fw-bold'>{generateUniqueName(user)}</span>
        </div>
      </DropdownToggle>
      <DropdownMenu className='py-1' end>
        <DropdownItem text>
          <UserInfo />
        </DropdownItem>
        <DropdownItem tag={Link} to='/tiers'>
          <span className='align-middle'>Upgrade Tier</span>
        </DropdownItem>
        <DropdownItem tag={Link} onClick={() => setSkin(skin === 'light' ? 'dark' : 'light')} >
          <span className='align-middle'>{ skin === 'light' ? 'Dark Theme' : 'Light Theme' }</span>
        </DropdownItem>
        <DropdownItem tag={Link} onClick={() => window.location.href = environment.legacyAppUrl } >
          <span className='align-middle'>Switch to Legacy UI</span>
        </DropdownItem>
        <DropdownItem tag={Link} to='/logout'>
          <span className='align-middle'>Logout</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default UserDropdown
