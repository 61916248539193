import { useCallback, useMemo } from 'react'
import axios from 'axios'
import useApiUrlBuilder from './useApiUrlBuilder'
import { transactionsEndpoints } from '../../constants/endpoints'

const useTransactions = () => {
  const buildApiUrl = useApiUrlBuilder()

  const getTransaction = useCallback(transactionHash => {
    return axios.get(buildApiUrl(transactionsEndpoints.resource, { transactionHash }))
  }, [buildApiUrl])

  const transactions = useMemo(() => ({
    api: {
      getTransaction
    }
  }), [getTransaction])

  return transactions
}

export default useTransactions
