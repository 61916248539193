// ** React Imports
import { useCallback } from 'react'

// ** Components Imports
import OneTimePayment from '../../components/one-time-payment'
import WalletToWalletPayment from '../../components/wallet-to-wallet-payment'
import ScheduledPayment from '../../components/scheduled-payment'
import Subscription from '../../components/subscription'

// ** Hooks Imports
import { useNavigate } from 'react-router-dom'
import useRightSidebar from './useRightSidebar'

// ** Utility Imports
import { ResourceType } from '../ResourceType'
import { BillingModel } from '../BillingModel'

const useClickHandler = () => {
  const { openSidebar } = useRightSidebar()
  const navigate = useNavigate()

  const handleOneTimePayment = useCallback(async oneTimeId => {
    openSidebar({
      title: 'Payment',
      content: <OneTimePayment oneTimeId={oneTimeId} />
    })
  }, [openSidebar])

  const handleFixedRecurringPlan = useCallback(planId => navigate(`/payments/fixed-recurring/${planId}`), [navigate])

  const handleFixedRecurringSubscription = useCallback((subscriptionId, props) => {
    openSidebar({
      title: 'Subscription',
      content: <Subscription billingModel={BillingModel.FIXED_RECURRING} subscriptionId={subscriptionId} {...props} />
    })
  }, [openSidebar])

  const handleVariableRecurringPlan = useCallback(planId => navigate(`/payments/variable-recurring/${planId}`), [navigate])

  const handleVariableRecurringSubscription = useCallback((subscriptionId, props) => {
    openSidebar({
      title: 'Subscription',
      content: <Subscription billingModel={BillingModel.VARIABLE_RECURRING} subscriptionId={subscriptionId} {...props} />
    })
  }, [openSidebar])

  const handleOnDemandPlan = useCallback(planId => navigate(`/payments/on-demand/${planId}`), [navigate])

  const handleOnDemandSubscription = useCallback((subscriptionId, props) => {
    openSidebar({
      title: 'Subscription',
      content: <Subscription billingModel={BillingModel.ON_DEMAND} subscriptionId={subscriptionId} {...props} />
    })
  }, [openSidebar])

  const handleWalletToWalletPayment = useCallback(paymentId => {
    openSidebar({
      title: 'Payment',
      content: <WalletToWalletPayment paymentId={paymentId} />
    })
  }, [openSidebar])

  const handleSchedule = useCallback(async (scheduleId, props) => {
    openSidebar({
      title: 'Scheduled Payment',
      content: <ScheduledPayment scheduleId={scheduleId} {...props} />
    })
  }, [openSidebar])

  const handleStrategy = useCallback(strategyId => navigate(`/invest/${strategyId}`), [navigate])

  const handleClick = useCallback((resourceType, resourceId, props = {}) => {
    switch (resourceType) {
      case ResourceType.ONE_TIME_PAYMENT:
        return handleOneTimePayment(resourceId)

      case ResourceType.FIXED_RECURRING_PLAN:
        return handleFixedRecurringPlan(resourceId)

      case ResourceType.FIXED_RECURRING_SUBSCRIPTION:
        return handleFixedRecurringSubscription(resourceId, props)

      case ResourceType.VARIABLE_RECURRING_PLAN:
        return handleVariableRecurringPlan(resourceId)

      case ResourceType.VARIABLE_RECURRING_SUBSCRIPTION:
        return handleVariableRecurringSubscription(resourceId, props)

      case ResourceType.ON_DEMAND_PLAN:
        return handleOnDemandPlan(resourceId)

      case ResourceType.ON_DEMAND_SUBSCRIPTION:
        return handleOnDemandSubscription(resourceId, props)

      case ResourceType.WALLET_TO_WALLET_PAYMENT:
        return handleWalletToWalletPayment(resourceId)

      case ResourceType.SCHEDULE:
        return handleSchedule(resourceId, props)

      case ResourceType.STRATEGY:
        return handleStrategy(resourceId)

      default:
        throw new Error('Unhandled resource type')
    }
  }, [
    handleFixedRecurringPlan,
    handleFixedRecurringSubscription,
    handleOnDemandPlan,
    handleOnDemandSubscription,
    handleOneTimePayment,
    handleSchedule,
    handleVariableRecurringPlan,
    handleVariableRecurringSubscription,
    handleStrategy,
    handleWalletToWalletPayment
  ])

  return { handleClick }
}

export default useClickHandler
