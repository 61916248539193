import { useCallback, useMemo } from 'react'
import axios from 'axios'
import useApiUrlBuilder from './useApiUrlBuilder'
import { notificationsEndpoints } from '../../constants/endpoints'

const useNotification = () => {
  const buildApiUrl = useApiUrlBuilder()

  const listNotifications = useCallback(params => {
    return axios.get(buildApiUrl(notificationsEndpoints.collection), { params })
  }, [buildApiUrl])

  const readNotification = useCallback(notificationId => {
    return axios.post(buildApiUrl(notificationsEndpoints.read, { notificationId }))
  }, [buildApiUrl])

  const notifications = useMemo(() => ({
    api: {
      listNotifications,
      readNotification
    }
  }), [listNotifications, readNotification])

  return notifications
}

export default useNotification

