import { useEffect, useMemo, useState, useCallback } from 'react'
import { readContract } from '@wagmi/core'
import useAuth from './useAuth'
import useDeployments from './useDeployments'
import useTokens from './useTokens'
import Tiers from '@src/constants/tiers'
import environment from '../../configs/environments'

const useTiers = () => {
  const { user, chain } = useAuth()
  const { account } = useAuth()
  const [tiersContract, setTiersContract] = useState()
  const [tiersStakingContract, setTiersStakingContract] = useState()
  const [currentTier, setCurrentTier] = useState(Tiers[0])
  const [pendingWithdrawal, setPendingWithdrawal] = useState(null)
  const [timelock, setTimelock] = useState(0)
  const [isReady, setIsReady] = useState(false)
  const deployments = useDeployments()
  const tokens = useTokens()

  const token = useMemo(() => environment.chains[chain].tiers.token, [chain])

  const loadData = useCallback(() => {
    readContract({
      address: tiersContract.address,
      abi: tiersContract.abi,
      functionName: 'getAccountTier',
      args: [user]
    }).then(result => setCurrentTier(Tiers.find(e => e.id === parseInt(result.toString()))))

    readContract({
      address: tiersStakingContract.address,
      abi: tiersStakingContract.abi,
      functionName: 'pendingWithdrawals',
      args: [user]
    }).then(result => {
      const amount = tokens.formatAmount(result[0].toString(), token)
      const timestamp = parseInt(result[1].toString())
      setPendingWithdrawal(amount !== '0' ? ({ amount, timestamp }) : null)
    })

    readContract({
      address: tiersStakingContract.address,
      abi: tiersStakingContract.abi,
      functionName: 'withdrawalTimelock'
    }).then(result => setTimelock(parseInt(result.toString())))
  }, [tiersContract, tiersStakingContract, tokens, user, token])

  const refresh = useCallback(() => loadData(), [loadData])

  useEffect(() => {
    if (deployments) {
      setTiersContract(deployments.get('Tiers'))
      setTiersStakingContract(deployments.get('TiersStaking'))
      setIsReady(true)
    }
  }, [deployments])

  useEffect(() => {
    if (tiersContract && tiersStakingContract && user && tokens) {
      loadData()
    }
  }, [tiersContract, tiersStakingContract, user, tokens, loadData])

  // ** Blockchain

  const withdraw = useCallback(() => ({
    address: tiersStakingContract.address,
    abi: tiersStakingContract.abi,
    functionName: 'withdraw',
    from: account
  }), [account, tiersStakingContract])

  const upgrade = useCallback(tier => {
    const tokensToDeposit = tokens.parseAmount(tier.price - currentTier.price, token)

    return {
      address: tiersStakingContract.address,
      abi: tiersStakingContract.abi,
      functionName: 'deposit',
      args: [tokensToDeposit],
      from: account
    }
  }, [account, tiersStakingContract, tokens, currentTier, token])

  const downgrade = useCallback(tier => {
    const tokensToWithdraw = tokens.parseAmount(currentTier.price - tier.price, token)

    return {
      address: tiersStakingContract.address,
      abi: tiersStakingContract.abi,
      functionName: 'requestWithdrawal',
      args: [tokensToWithdraw],
      from: account
    }
  }, [account, tiersStakingContract, tokens, currentTier, token])

  return useMemo(() => ({
    isReady,
    currentTier,
    pendingWithdrawal,
    withdraw,
    upgrade,
    downgrade,
    timelock,
    refresh
  }), [isReady, currentTier, pendingWithdrawal, withdraw, upgrade, downgrade, timelock, refresh])
}

export default useTiers
