import { useContext } from 'react'
import { Websocket } from '@src/utility/context/Websocket'

const useWebsocket = () => {
  const { websocket, isReady } = useContext(Websocket)

  return { websocket, isReady }
}

export default useWebsocket
