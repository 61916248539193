import { lazy } from 'react'
import { BillingModel } from '../../utility/BillingModel'
import { PaymentTab } from '../../utility/PaymentTab'

const Payments = lazy(() => import('../../views/payments'))
const Plan = lazy(() => import('../../views/payments/plans/Plan'))
const ManageSubscriptions = lazy(() => import('../../views/payments/plans/ManageSubscriptions'))

const PaymentsRoutes = [
  {
    path: '/payments',
    element: <Payments activeTab={PaymentTab.TRANSACTIONS}/>
  },
  {
    path: '/payments/transactions',
    element: <Payments activeTab={PaymentTab.TRANSACTIONS}/>
  },
  {
    path: '/payments/automatic-payments',
    element: <Payments activeTab={PaymentTab.AUTOMATIC_PAYMENTS}/>
  },
  {
    path: '/payments/scheduled-payments',
    element: <Payments activeTab={PaymentTab.SCHEDULED_PAYMENTS}/>
  },
  {
    path: '/payments/fixed-recurring/:planId',
    element: <Plan billingModel={BillingModel.FIXED_RECURRING}/>
  },
  {
    path: '/payments/variable-recurring/:planId',
    element: <Plan billingModel={BillingModel.VARIABLE_RECURRING}/>
  },
  {
    path: '/payments/on-demand/:planId',
    element: <Plan billingModel={BillingModel.ON_DEMAND}/>
  },
  {
    path: '/payments/fixed-recurring/:planId/subscriptions',
    element: <ManageSubscriptions billingModel={BillingModel.FIXED_RECURRING}/>
  },
  {
    path: '/payments/variable-recurring/:planId/subscriptions',
    element: <ManageSubscriptions billingModel={BillingModel.VARIABLE_RECURRING}/>
  },
  {
    path: '/payments/on-demand/:planId/subscriptions',
    element: <ManageSubscriptions billingModel={BillingModel.ON_DEMAND}/>
  }
]

export default PaymentsRoutes
