import { useCallback, useMemo } from 'react'
import axios from 'axios'
import { ratesEndpoints } from '../../constants/endpoints'

const useExchangeRates = () => {
  const history = useCallback((from, to) => {
    return axios.get(ratesEndpoints.history, { params: { from, to } })
  }, [])

  const exchangeRates = useMemo(() => ({
    api: {
      history
    }
  }), [history])

  return exchangeRates
}

export default useExchangeRates
