import { useState, useCallback, useEffect, useMemo } from 'react'
import axios from 'axios'
import useAuth from './useAuth'
import useDeployments from './useDeployments'
import useApiUrlBuilder from './useApiUrlBuilder'
import { scheduledPaymentsEndpoints } from '../../constants/endpoints'

const useScheduledPayments = () => {
  const { account } = useAuth()
  const [contract, setContract] = useState()
  const deployments = useDeployments()
  const buildApiUrl = useApiUrlBuilder()

  useEffect(() => {
    if (deployments) {
      setContract(deployments.get('ScheduledPayments'))
    }
  }, [deployments])

  const getSchedule = useCallback(scheduleId => {
    return axios.get(buildApiUrl(scheduledPaymentsEndpoints.schedules.resource, { scheduleId }))
  }, [buildApiUrl])

  const listScheduleExecutions = useCallback(scheduleId => {
    return axios.get(buildApiUrl(scheduledPaymentsEndpoints.schedules.executions, { scheduleId }))
  }, [buildApiUrl])

  const listSchedules = useCallback(params => {
    return axios.get(buildApiUrl(scheduledPaymentsEndpoints.schedules.collection), { params })
  }, [buildApiUrl])

  const listTransfers = useCallback(params => {
    return axios.get(buildApiUrl(scheduledPaymentsEndpoints.transfers.collection), { params })
  }, [buildApiUrl])

  const createSchedule = useCallback((name, start, token, repeat, receivers, amounts, category) => ({
    address: contract.address,
    abi: contract.abi,
    functionName: 'createSchedule',
    args: [name, start, token, repeat, receivers, amounts, category],
    from: account
  }), [account, contract])

  const cancelSchedule = useCallback(scheduleId => ({
    address: contract.address,
    abi: contract.abi,
    functionName: 'cancelSchedule',
    args: [scheduleId],
    from: account
  }), [account, contract])

  const scheduledPayments = useMemo(() => ({
    api: {
      getSchedule,
      listScheduleExecutions,
      listSchedules,
      listTransfers
    },
    blockchain: {
      createSchedule,
      cancelSchedule
    }
  }), [getSchedule, listScheduleExecutions, listSchedules, listTransfers, createSchedule, cancelSchedule])

  return scheduledPayments
}

export default useScheduledPayments
