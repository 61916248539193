import development from './development.js'
import staging from './staging.js'
import production from './production.js'

const environment = process.env.REACT_APP_ENVIRONMENT === 'production' ? production : (process.env.REACT_APP_ENVIRONMENT === 'development' ? development : staging)

export default {
  ...environment,
  isMobileBuild: process.env.REACT_APP_MOBILE_BUILD === 'true'
}
