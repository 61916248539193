// ** React Imports
import { createContext, useState } from 'react'

// ** Reactstrap Imports
import { Offcanvas } from 'react-bootstrap'

// ** Third Party Components
import { ArrowRight } from 'react-feather'

const RightSidebar = createContext()

const RightSidebarProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [title, setTitle] = useState({})
  const [content, setContent] = useState({})

  const openSidebar = ({ title, content }) => {
    setTitle(title)
    setContent(content)
    setIsOpen(true)
  }

  const closeSidebar = () => setIsOpen(false)

  return (
    <RightSidebar.Provider value={{ openSidebar, closeSidebar }}>
      <Offcanvas id='right-sidebar' placement='end' show={isOpen} onClose={closeSidebar} onHide={closeSidebar}>
        <div className='header'>
          <ArrowRight onClick={closeSidebar} />
          <h3 className='mx-auto mb-0'>{title}</h3>
        </div>
        <Offcanvas.Body>{content}</Offcanvas.Body>
      </Offcanvas>
      {children}
    </RightSidebar.Provider>
  )
}

export { RightSidebar, RightSidebarProvider }
