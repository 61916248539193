import { useState, useCallback, useEffect, useMemo } from 'react'
import { Edit } from 'react-feather'
import { Button } from 'reactstrap'
import CopyToClipboard from '../copy-to-clipboard'
import BillingStatusCircle from '../../components/billing-status-circle'
import SubscriptionStatusBadge from '../../components/subscription-status-badge'
import useAuth from '../../utility/hooks/useAuth'
import useFixedRecurring from '@hooks/useFixedRecurring'
import useVariableRecurring from '@hooks/useVariableRecurring'
import useTransactionSender from '@hooks/useTransactionSender'
import useOnDemand from '@hooks/useOnDemand'
import { BillingModel, BillingModelName } from '../../utility/BillingModel'
import { SubscriptionStatus } from '../../utility/SubscriptionStatus'
import { formatDateTime, formatTokenAmount, getExplorerUrl, intervalToText, shortHash, getNetAmount } from '../../utility/Utils'

const Subscription = ({ billingModel, subscriptionId, onSubscriptionUpdated }) => {
  const { chain, user } = useAuth()
  const [subscription, setSubscription] = useState()
  const [billings, setBillings] = useState()
  const [plan, setPlan] = useState()
  const [isLoading, setIsLoading] = useState(true)

  const fixedRecurring = useFixedRecurring()
  const variableRecurring = useVariableRecurring()
  const onDemand = useOnDemand()

  const { openTransactionSender } = useTransactionSender()

  const billingModelObjects = useMemo(() => ({
    [BillingModel.FIXED_RECURRING]: fixedRecurring,
    [BillingModel.VARIABLE_RECURRING]: variableRecurring,
    [BillingModel.ON_DEMAND]: onDemand
  }), [fixedRecurring, variableRecurring, onDemand])

  const loadData = useCallback(async () => {
    setIsLoading(true)

    const { data: subscription } = await billingModelObjects[billingModel].api.getSubscription(subscriptionId)
    const { data: { data: billings } } = await billingModelObjects[billingModel].api.listBillingsBySubscription(subscriptionId)
    const { data: plan } = await billingModelObjects[billingModel].api.getPlan(subscription.planId)

    setSubscription(subscription)
    setBillings(billings)
    setPlan(plan)
    setIsLoading(false)
  }, [subscriptionId, billingModelObjects, billingModel])

  const cancel = () => {
    const functionName = billingModel === BillingModel.VARIABLE_RECURRING ? 'requestSubscriptionCancellation' : 'cancelSubscription'
    openTransactionSender({
      title: 'Cancel subscription',
      description: `If you confirm and end your subscription now, you won't be charged anymore and you might lose access to the service "${plan.name}".`,
      transaction: billingModelObjects[billingModel].blockchain[functionName](subscription.id),
      websocket: true
    })
      .onSuccess(async () => {
        const { data: newSubscription } = await billingModelObjects[billingModel].api.getSubscription(subscriptionId)
        setSubscription(newSubscription)
        onSubscriptionUpdated(newSubscription)
      })
  }

  useEffect(() => {
    loadData()
  }, [loadData])

  if (isLoading) {
    return
  }

  return (
    <div className='d-flex flex-column h-100'>
      <div className='d-flex align-items-center justify-content-between mb-1'>
        <div>ID</div>
        <div className='d-flex align-items-center justify-content-end fw-bold'>
          <div className='me-50'>{shortHash(subscription.id)}</div>
          <CopyToClipboard text={subscription.id} />
        </div>
      </div>

      <div className='d-flex align-items-center justify-content-between mb-1'>
        <div>Status</div>
        <div className='d-flex align-items-center justify-content-end fw-bold'>
          <SubscriptionStatusBadge status={subscription.status} />
        </div>
      </div>

      <div className='d-flex align-items-center justify-content-between mb-1'>
        <div>Plan</div>
        <div className='fw-bold'>{plan.name}</div>
      </div>

      <div className='d-flex align-items-center justify-content-between mb-1'>
        <div>Type</div>
        <div className='fw-bold'>{BillingModelName[billingModel]}</div>
      </div>

      {subscription.user === user && (
        <div className='d-flex align-items-center justify-content-between mb-1'>
          <div>Admin</div>
          <div className='d-flex align-items-center justify-content-end fw-bold'>
            <div className='me-50'>{shortHash(plan.admin)}</div>
            <CopyToClipboard text={plan.admin} />
          </div>
        </div>
      )}

      {subscription.user !== user && (
        <div className='d-flex align-items-center justify-content-between mb-1'>
          <div>Customer</div>
          <div className='d-flex align-items-center justify-content-end fw-bold'>
            <div className='me-50'>{shortHash(subscription.user)}</div>
            <CopyToClipboard text={subscription.user} />
          </div>
        </div>
      )}

      {billingModel === BillingModel.FIXED_RECURRING && (
        <div className='d-flex align-items-center justify-content-between mb-1'>
          <div>Amount</div>
          <div className='d-flex align-items-center justify-content-end fw-bold'>
            {formatTokenAmount(plan.amount)} {plan.token}
          </div>
        </div>
      )}

      {billingModel === BillingModel.VARIABLE_RECURRING && (
        <div className='d-flex align-items-center justify-content-between mb-1'>
          <div>Max. Amount</div>
          <div className='d-flex align-items-center justify-content-end fw-bold'>
            {formatTokenAmount(plan.maxAmount)} {plan.token}
          </div>
        </div>
      )}

      <div className='d-flex align-items-center justify-content-between mb-1'>
        <div>Period</div>
        <div className='d-flex align-items-center justify-content-end fw-bold'>
          {intervalToText(plan.period)}
        </div>
      </div>

      <div className='d-flex align-items-center justify-content-between mb-1'>
        <div>Subscribed On</div>
        <div className='d-flex align-items-center justify-content-end fw-bold'>
          {formatDateTime(subscription.subscribedAt * 1000)}
        </div>
      </div>

      {subscription.status === SubscriptionStatus.ACTIVE && (
        <>
          <div className='d-flex align-items-center justify-content-between mb-1'>
            <div>Cycle Start</div>
            <div className='d-flex align-items-center justify-content-end fw-bold'>
              {formatDateTime(subscription.cycleStart * 1000)}
            </div>
          </div>

          <div className='d-flex align-items-center justify-content-between mb-1'>
            <div>Cycle End</div>
            <div className='d-flex align-items-center justify-content-end fw-bold'>
              {formatDateTime(subscription.cycleEnd * 1000)}
            </div>
          </div>

          {billingModel === BillingModel.ON_DEMAND && (
            <div className='d-flex align-items-center justify-content-between mb-1'>
              <div>Usage</div>
              <div className='d-flex align-items-center justify-content-end fw-bold'>
                <div className='me-50'>
                  {formatTokenAmount(subscription.cycleUsage)}/{formatTokenAmount(subscription.allowance)} {plan.token}
                </div>
                <Edit className='cursor-pointer' size={16} />
              </div>
            </div>
          )}
        </>
      )}

      {subscription.status === SubscriptionStatus.EXPIRED && (
        <div className='d-flex align-items-center justify-content-between mb-1'>
          <div>Expired On</div>
          <div className='d-flex align-items-center justify-content-end fw-bold'>
            {formatDateTime(subscription.cycleEnd * 1000)}
          </div>
        </div>
      )}

      {(subscription.status === SubscriptionStatus.CANCELLED || subscription.status === SubscriptionStatus.TERMINATED) && (
        <div className='d-flex align-items-center justify-content-between mb-1'>
          <div>Cancelled On</div>
          <div className='d-flex align-items-center justify-content-end fw-bold'>
            {formatDateTime(subscription.cancelledAt * 1000)}
          </div>
        </div>
      )}

      {subscription.status === SubscriptionStatus.CANCELLATION_REQUESTED && (
        <div className='d-flex align-items-center justify-content-between mb-1'>
          <div>Cancellation Requested On</div>
          <div className='d-flex align-items-center justify-content-end fw-bold'>
            {formatDateTime(subscription.cancellationRequestedAt * 1000)}
          </div>
        </div>
      )}

      <div className='d-flex align-items-center justify-content-between mb-1'>
        <div>Transaction Hash</div>
        <div className='d-flex align-items-center justify-content-end fw-bold'>
          <a className='text-decoration-underline me-50' href={getExplorerUrl(chain, subscription.transactionHash)} target='_blank'>
            {shortHash(subscription.transactionHash)}
          </a>
          <CopyToClipboard text={subscription.transactionHash} />
        </div>
      </div>

      {!!billings.length && (
        <div className='my-3'>
          <h4 className='mb-1'>Billings</h4>
          <table className='w-100'>
            <thead>
              <tr>
                <th>Date</th>
                <th className='text-end'>Amount</th>
                <th className='text-end'>Transaction Hash</th>
              </tr>
            </thead>
            <tbody>
              {billings.map(e => (
                <tr key={e.transactionHash}>
                  <td>{formatDateTime(e.timestamp * 1000)}</td>
                  <td className='text-end'>
                    {formatTokenAmount(user === plan.admin || user === plan.receiver ? getNetAmount(e.amount, e.fee) : e.amount)} {e.token}
                  </td>
                  <td className='d-flex align-items-center justify-content-end'>
                    <a className='text-decoration-underline me-50' href={getExplorerUrl(chain, e.transactionHash)} target='_blank'>
                      {shortHash(e.transactionHash)}
                    </a>
                    <div className='me-50'>
                      <BillingStatusCircle success={e.success} />
                    </div>
                    <CopyToClipboard text={e.transactionHash} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {subscription.user === user && (subscription.status === SubscriptionStatus.ACTIVE || subscription.status === SubscriptionStatus.EXPIRED) && (
        <div className='mt-auto pb-2'>
          <Button.Ripple color='danger' className='round' block onClick={() => cancel()}>Unsubscribe</Button.Ripple>
        </div>
      )}
    </div>
  )

}

export default Subscription
