// ** React Imports
import { Suspense, lazy } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

// ** Redux Imports
import { store } from './redux/store'
import { Provider } from 'react-redux'

// ** Intl, CASL & ThemeColors Context
import ability from './configs/acl/ability'
import { AbilityContext } from './utility/context/Can'
import { ThemeContext } from './utility/context/ThemeColors'

// ** ThemeConfig
import themeConfig from './configs/themeConfig'

// ** Toast
import { Toaster } from 'react-hot-toast'

// ** i18n
import './configs/i18n'

// ** Spinner (Splash Screen)
import Spinner from './components/spinner'

// ** Ripple Button
import './@core/components/ripple-button'

// ** Fake Database
import './@fake-db'

// ** PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-jsx.min'

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css'

// ** React Hot Toast Styles
import '@styles/react/libs/react-hot-toasts/react-hot-toasts.scss'

// ** Core styles
import './@core/assets/fonts/feather/iconfont.css'
import './@core/scss/core.scss'
import './assets/scss/style.scss'

import { WagmiConfig } from 'wagmi'
import { config as wagmiConfig } from './configs/wagmi'

import { ModalProvider } from '@src/utility/context/Modal'
import { RightSidebarProvider } from '@src/utility/context/RightSidebar'
import { BalancesProvider } from '@src/utility/context/Balances'
import { UsdRatesProvider } from '@src/utility/context/UsdRates'
import { WebsocketProvider } from '@src/utility/context/Websocket'
import { AllowancesProvider } from '@src/utility/context/Allowances'

// ** Lazy load app
const LazyApp = lazy(() => import('./App'))

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <BrowserRouter basename={process.env.PUBLIC_URL}>
    <Provider store={store}>
      <WagmiConfig config={wagmiConfig}>
        <BalancesProvider>
          <AllowancesProvider>
            <Suspense fallback={<Spinner />}>
              <AbilityContext.Provider value={ability}>
                <ThemeContext>
                  <WebsocketProvider>
                    <ModalProvider>
                      <UsdRatesProvider>
                        <RightSidebarProvider>
                          <LazyApp />
                          <Toaster position={themeConfig.layout.toastPosition} toastOptions={{ className: 'react-hot-toast' }} />
                        </RightSidebarProvider>
                      </UsdRatesProvider>
                    </ModalProvider>
                  </WebsocketProvider>
                </ThemeContext>
              </AbilityContext.Provider>
            </Suspense>
          </AllowancesProvider>
        </BalancesProvider>
      </WagmiConfig>
    </Provider>
  </BrowserRouter>
)
