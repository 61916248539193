import { useCallback, useEffect, useState } from 'react'
import CopyToClipboard from '../copy-to-clipboard'
import useAuth from '../../utility/hooks/useAuth'
import { formatDateTime, formatTokenAmount, getExplorerUrl, shortHash, getNetAmount } from '../../utility/Utils'
import useOneTime from '@hooks/useOneTime'

const OneTimePayment = ({ oneTimeId }) => {
  const { chain, user } = useAuth()
  const [oneTime, setOneTime] = useState()
  const { api: { getPayment } } = useOneTime()

  const loadOneTime = useCallback(async () => {
    const { data: response } = await getPayment(oneTimeId)
    setOneTime(response)
  }, [oneTimeId, getPayment])

  useEffect(() => {
    loadOneTime()
  }, [loadOneTime])

  if (!oneTime) {
    return
  }

  return (
    <div>
      <div className='d-flex align-items-center justify-content-between mb-1'>
        <div>ID</div>
        <div className='d-flex align-items-center justify-content-end fw-bold'>
          <div className='me-50'>{shortHash(oneTime.id)}</div>
          <CopyToClipboard text={oneTime.id} />
        </div>
      </div>

      <div className='d-flex align-items-center justify-content-between mb-1'>
        <div>Description</div>
        <div className='d-flex align-items-center justify-content-end fw-bold'>
          {oneTime.description}
        </div>
      </div>

      <div className='d-flex align-items-center justify-content-between mb-1'>
        <div>Sender</div>
        <div className='d-flex align-items-center justify-content-end fw-bold'>
          <div className='me-50'>{shortHash(oneTime.sender)}</div>
          <CopyToClipboard text={oneTime.sender} />
        </div>
      </div>

      <div className='d-flex align-items-center justify-content-between mb-1'>
        <div>Transaction Hash</div>
        <div className='d-flex align-items-center justify-content-end fw-bold'>
          <a className='text-decoration-underline me-50' href={getExplorerUrl(chain, oneTime.transactionHash)} target='_blank'>
            {shortHash(oneTime.transactionHash)}
          </a>
          <CopyToClipboard text={oneTime.transactionHash} />
        </div>
      </div>

      <div className='d-flex align-items-center justify-content-between mb-1'>
        <div>Paid On</div>
        <div className='d-flex align-items-center justify-content-end fw-bold'>
          {formatDateTime(oneTime.timestamp * 1000)}
        </div>
      </div>

      <div className='my-3'>
        <h4 className='mb-1'>Receivers</h4>
        <table className='w-100'>
          <thead>
            <tr>
              <th>User</th>
              <th className='text-end'>Amount</th>
            </tr>
          </thead>
          <tbody>
            {oneTime.receivers.map(e => (
              <tr key={e.user}>
                <td>{shortHash(e.user)}</td>
                <td className='text-end'>
                  {formatTokenAmount(e.user === user ? getNetAmount(e.amount, e.fee) : e.amount)} {oneTime.token}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default OneTimePayment
