import { lazy } from 'react'

const Tiers = lazy(() => import('../../views/tiers'))

const TiersRoutes = [
  {
    path: '/tiers',
    element: <Tiers />
  }
]

export default TiersRoutes
