import { uniqueNamesGenerator, adjectives, animals } from 'unique-names-generator'
import BigNumber from 'bignumber.js'
import { DefaultRoute } from '../router/routes'
import environments from '../configs/environments'

// ** Configs
import authConfig from '../configs/auth'

const storageKeys = Object.keys(authConfig.storageKeys)

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

export const formatDateTime = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => !!localStorage.getItem(authConfig.storageKeys.accessToken)
export const getUserData = () => storageKeys.reduce((state, key) => ({ ...state, [key]: localStorage.getItem(key) }), {})

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'admin') return DefaultRoute
  if (userRole === 'client') return '/access-control'
  return '/login'
}

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})

export const generateUniqueName = seed => uniqueNamesGenerator({
  dictionaries: [adjectives, animals],
  length: 2,
  separator: ' ',
  style: 'capital',
  seed: seed
})

export const forceResolvePromise = promise => new Promise(resolve => promise.then(resolve).catch(() => forceResolvePromise(promise)))

export const formatUsdAmount = amount => amount.toLocaleString("en-US", { minimumFractionDigits: 2 })
export const formatTokenAmount = amount => +new BigNumber(amount).toFixed(5)

export const intervalToText = seconds => {
  const MINUTE = 60
  const HOUR = MINUTE * 60
  const DAY = HOUR * 24
  const WEEK = DAY * 7
  const MONTH = DAY * 30
  const YEAR = DAY * 365

  if (seconds === DAY) {
    return 'Daily'
  }

  if (seconds === WEEK) {
    return 'Weekly'
  }

  if (seconds === MONTH) {
    return 'Monthly'
  }

  if (seconds === YEAR) {
    return 'Yearly'
  }

  if (seconds < HOUR) {
    const minutes = Math.floor(seconds / MINUTE)
    return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`
  }

  if (seconds < DAY) {
    const hours = Math.floor(seconds / HOUR)
    return `${hours} ${hours === 1 ? 'hour' : 'hours'}`
  }

  if (seconds < WEEK) {
    const days = Math.floor(seconds / DAY)
    return `${days} ${days === 1 ? 'day' : 'days'}`
  }

  if (seconds < MONTH) {
    const weeks = Math.floor(seconds / WEEK)
    return `${weeks} ${weeks === 1 ? 'week' : 'weeks'}`
  }

  if (seconds < YEAR) {
    const months = Math.floor(seconds / MONTH)
    return `${months} ${months === 1 ? 'month' : 'months'}`
  }

  const years = Math.floor(seconds / YEAR)
  return `${years} ${years === 1 ? 'year' : 'years'}`
}

export const shortHash = (hash, start = 6, end = 4) => `${hash.slice(0, start)}...${hash.slice(-end)}`

export const getExplorerUrl = (chain, transactionHash) => `${environments.chains[chain].config.blockExplorerUrls[0]}/tx/${transactionHash}`

export const getTimePeriods = seconds => {
  const MINUTE = 60
  const HOUR = MINUTE * 60
  const DAY = HOUR * 24
  const MONTH = DAY * 30
  const YEAR = DAY * 365

  let delta = Math.abs(seconds)

  const timeLeft = {
    years: 0,
    months: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  }

  if (delta >= YEAR) {
    timeLeft.years = Math.floor(delta / YEAR)
    delta -= timeLeft.years * YEAR
  }

  if (delta >= MONTH) {
    timeLeft.months = Math.floor(delta / MONTH)
    delta -= timeLeft.months * MONTH
  }

  if (delta >= DAY) {
    timeLeft.days = Math.floor(delta / DAY)
    delta -= timeLeft.days * DAY
  }

  if (delta >= HOUR) {
    timeLeft.hours = Math.floor(delta / HOUR)
    delta -= timeLeft.hours * HOUR
  }

  if (delta >= MINUTE) {
    timeLeft.minutes = Math.floor(delta / MINUTE)
    delta -= timeLeft.minutes * MINUTE
  }

  timeLeft.seconds = delta

  return timeLeft
}

export const formatTimePeriod = (periods, excludePeriods = []) => {
  const textArr = []

  Object.keys(periods).forEach(period => {
    if (periods[period] > 0 && !excludePeriods.includes(period)) {
      textArr.push(`${periods[period]}${period.slice(0, 1)}`)
    }
  })

  if (textArr.length === 0) {
    return null
  }

  return textArr.join(' ')
}

export const getNetAmount = (amount, fee) => new BigNumber(amount).minus(fee).toFixed()
