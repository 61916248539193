// ** React Imports
import { lazy } from 'react'

const Invest = lazy(() => import('../../views/invest'))
const Strategy = lazy(() => import('../../views/invest/Strategy'))

const InvestRoutes = [
  {
    path: '/invest',
    element: <Invest />
  },
  {
    path: '/invest/:strategyId',
    element: <Strategy />
  }
]

export default InvestRoutes
