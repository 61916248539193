import { Badge } from "reactstrap"

const ScheduleStatusColor = {
  ACTIVE: 'light-success',
  CANCELLED: 'light-danger',
  COMPLETED: 'light-secondary'
}

const ScheduleStatusBadge = ({ status, className }) => {
  return (
    <Badge className={`text-capitalize ${className}`} color={ScheduleStatusColor[status]} pill>
      {status.toLowerCase()}
    </Badge>
  )
}

export default ScheduleStatusBadge
