import { useMemo } from 'react'
import { useSelector } from 'react-redux'

const useAuth = () => {
  const auth = useSelector(store => store.auth)

  return useMemo(() => ({
    accessToken: auth.accessToken,
    accessTokenExpiresAt: parseInt(auth.accessTokenExpiresAt),
    accessTokenIssuedAt: parseInt(auth.accessTokenIssuedAt),
    chain: auth.chain,
    connector: auth.connector,
    user: auth.user,
    loggedIn: auth.accessToken && parseInt(auth.accessTokenExpiresAt) > Math.floor(Date.now() / 1000)
  }), [auth])
}

export default useAuth
