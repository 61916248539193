
import { useState, useEffect, useCallback } from 'react'
import CopyToClipboard from '../copy-to-clipboard'
import useAuth from '../../utility/hooks/useAuth'
import { formatDateTime, formatTokenAmount, shortHash, getNetAmount } from '../../utility/Utils'
import useWalletToWallet from '@hooks/useWalletToWallet'

const WalletToWalletPayment = ({ paymentId }) => {
  const { user } = useAuth()

  const [payment, setPayment] = useState()
  const { api: { getPayment } } = useWalletToWallet()

  const loadPayment = useCallback(async () => {
    const { data: response } = await getPayment(paymentId)
    setPayment(response)
  }, [paymentId, getPayment])

  useEffect(() => {
    loadPayment()
  }, [loadPayment])

  if (!payment) {
    return
  }

  return (
    <div>
      <div className='d-flex align-items-center justify-content-between mb-1'>
        <div>ID</div>
        <div className='d-flex align-items-center justify-content-end fw-bold'>
          <div className='me-50'>{shortHash(payment.id)}</div>
          <CopyToClipboard text={payment.id} />
        </div>
      </div>

      <div className='d-flex align-items-center justify-content-between mb-1'>
        <div>Description</div>
        <div className='d-flex align-items-center justify-content-end fw-bold'>
          {payment.description}
        </div>
      </div>

      <div className='d-flex align-items-center justify-content-between mb-1'>
        <div>Email</div>
        <div className='d-flex align-items-center justify-content-end fw-bold'>
          {payment.email || '-'}
        </div>
      </div>

      <div className='d-flex align-items-center justify-content-between mb-1'>
        <div>Service Fee</div>
        <div className='d-flex align-items-center justify-content-end fw-bold'>
          {payment.serviceFee} {payment.token}
        </div>
      </div>

      <div className='d-flex align-items-center justify-content-between mb-1'>
        <div>Paid On</div>
        <div className='d-flex align-items-center justify-content-end fw-bold'>
          {formatDateTime(payment.receivers[0].timestamp * 1000)}
        </div>
      </div>

      <div className='my-3'>
        <h4 className='mb-1'>Receivers</h4>
        <table className='w-100'>
          <thead>
            <tr>
              <th>User</th>
              <th className='text-end'>Amount</th>
            </tr>
          </thead>
          <tbody>
            {payment.receivers.map(e => (
              <tr key={e.user}>
                <td>{shortHash(e.user)}</td>
                <td className='text-end'>
                  {formatTokenAmount(e.user === user ? getNetAmount(e.amount, e.fee) : e.amount)} {payment.token}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default WalletToWalletPayment
