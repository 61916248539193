import baseTierImg from '@src/assets/images/tiers/base.svg'
import silverTierImg from '@src/assets/images/tiers/silver.svg'
import goldTierImg from '@src/assets/images/tiers/gold.svg'
import platinumTierImg from '@src/assets/images/tiers/platinum.svg'

const Tiers = [
  {
    id: 0,
    name: 'Base',
    image: baseTierImg,
    price: 0,
    fees: {
      oneTimePayments: 1,
      walletToWallet: 1,
      recurringPayments: 1,
      autobiller: 0.20,
      scheduledPayments: 1,
      dollarCostAverage: 2
    }
  },
  {
    id: 1,
    name: 'Silver',
    image: silverTierImg,
    price: 13000,
    fees: {
      oneTimePayments: 1,
      walletToWallet: 1,
      recurringPayments: 1,
      autobiller: 0.15,
      scheduledPayments: 0.8,
      dollarCostAverage: 1
    }
  },
  {
    id: 2,
    name: 'Gold',
    image: goldTierImg,
    price: 46000,
    fees: {
      oneTimePayments: 0.8,
      walletToWallet: 1,
      recurringPayments: 0.8,
      autobiller: 0.08,
      scheduledPayments: 0.5,
      dollarCostAverage: 0.5
    }
  },
  {
    id: 3,
    name: 'Platinum',
    image: platinumTierImg,
    price: 166000,
    fees: {
      oneTimePayments: 0.5,
      walletToWallet: 1,
      recurringPayments: 0.5,
      autobiller: 0,
      scheduledPayments: 0,
      dollarCostAverage: 0
    }
  }
]

export default Tiers
