// ** React Imports
import { createContext, useRef, useState, useCallback } from 'react'

// ** Reactstrap Imports
import { Button, Modal as ReactstrapModal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

const Modal = createContext()

class Wrapper {
  constructor(promise) {
    promise.then(result => {
      if (result) {
        this.confirmCb?.()
      } else {
        this.cancelCb?.()
      }
    })
  }

  onConfirm(cb) {
    this.confirmCb = cb
    return this
  }

  onCancel(cb) {
    this.cancelCb = cb
    return this
  }
}

const ModalProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [confirmButton, setConfirmButton] = useState()
  const [cancelButton, setCancelButton] = useState()
  const resolver = useRef()

  const openModal = useCallback(({ title, description, confirmButton, cancelButton }) => {
    setTitle(title)
    setDescription(description)
    setConfirmButton(confirmButton)
    setCancelButton(cancelButton)
    setIsOpen(true)
    return new Wrapper(new Promise(resolve => resolver.current = resolve))
  }, [])

  const closeModal = useCallback(() => setIsOpen(false), [])

  const onConfirm = () => {
    resolver.current(true)
    setIsOpen(false)
  }

  const onCancel = () => {
    resolver.current(false)
    setIsOpen(false)
  }

  return (
    <Modal.Provider value={{ openModal, closeModal }}>
      <ReactstrapModal isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} onClose={onCancel} backdrop='static' className='modal-dialog-centered'>
        {title && <ModalHeader close={<></>} toggle={() => setIsOpen(!isOpen)}>{title}</ModalHeader>}
        {description && <ModalBody className='pb-1'>{description}</ModalBody>}
        {(cancelButton || confirmButton) && <ModalFooter>
          {cancelButton && <Button color={cancelButton.color || 'light'} onClick={onCancel}>{cancelButton.text}</Button>}
          {confirmButton && <Button color={confirmButton.color || 'primary'} onClick={onConfirm}>{confirmButton.text}</Button>}
        </ModalFooter>}
      </ReactstrapModal>
      {children}
    </Modal.Provider>
  )
}

export { Modal, ModalProvider }
