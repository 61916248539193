import { useCallback } from 'react'
import { Home, CreditCard, RefreshCw, DollarSign } from 'react-feather'
import { QrCode } from 'lucide-react'
import { useLocation, useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { useSkin } from '@hooks/useSkin'
import useRightSidebar from '@hooks/useRightSidebar'
import Qrscan from '../../../components/qr-reader'

const MobileNavigation = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { skin } = useSkin()
  const { openSidebar } = useRightSidebar()

  const handleQrScanResult = result => {
    console.log(result)

    if (result.startsWith('https://me.8pay.tech')) {
      window.location.href = result.replace('https://me.8pay.tech', 'https://m.8pay.tech/checkout')
    }
  }

  const openQrScannerSidebar = useCallback(() => openSidebar({
    title: 'Scan QR Code',
    content: <Qrscan onResult={handleQrScanResult}/>
  }), [openSidebar])

  // const openQrCodeScanner = () => {
  //   window.webkit?.messageHandlers.handler?.postMessage('scanQrCode')
  //   window.android?.postMessage('scanQrCode')
  // }

  return (
    <div className={`mobile-navigation ${skin === 'light' ? 'light' : 'dark'} py-1 px-50`}>
      <div className='d-flex'>
        <div
          className={classNames('d-flex flex-column align-items-center mx-auto w-100', { 'text-primary': location.pathname.startsWith('/home') })}
          onClick={() => navigate('/home')}
        >
          <Home size={24}/>
          <div className='small'>Home</div>
        </div>
        <div
          className={classNames('d-flex flex-column align-items-center mx-auto w-100', { 'text-primary': location.pathname.startsWith('/wallet') })}
          onClick={() => navigate('/wallet')}
        >
          <CreditCard size={24}/>
          <div className='small'>Wallet</div>
        </div>
        <div className='d-flex flex-column align-items-center mx-auto w-100' onClick={openQrScannerSidebar}>
          <QrCode size={24} />
          <div className='small text-nowrap'>Scan & Pay</div>
        </div>
        <div
          className={classNames('d-flex flex-column align-items-center mx-auto w-100', { 'text-primary': location.pathname.startsWith('/payments') })}
          onClick={() => navigate('/payments')}
        >
          <RefreshCw size={24}/>
          <div className='small'>Payments</div>
        </div>
        <div
          className={classNames('d-flex flex-column align-items-center mx-auto w-100', { 'text-primary': location.pathname.startsWith('/invest') })}
          onClick={() => navigate('/invest')}
        >
          <DollarSign size={24}/>
          <div className='small'>Invest</div>
        </div>
      </div>
    </div>
  )
}

export default MobileNavigation
