// ** React Imports
import { Link } from 'react-router-dom'

import { Menu } from 'react-feather'

// ** Reactstrap Imports
import { Navbar, NavItem, NavLink } from 'reactstrap'

// ** Custom Components
import NotificationDropdown from '../common/NotificationDropdown'

// ** Custom Hooks
import { useSkin } from '@hooks/useSkin'
import { useNavbarColor } from '@hooks/useNavbarColor'
import useRightSidebar from '@hooks/useRightSidebar'

// ** Images
import logo from '@src/assets/images/logo.svg'
import logoFullWhite from '@src/assets/images/logo-full-white.png'

const VerticalNavbar = props => {
  const { setMenuVisibility } = props

  // ** Hooks
  const { skin } = useSkin()
  const { navbarColor } = useNavbarColor()
  const { closeSidebar } = useRightSidebar()

  const bgColorCondition = navbarColor !== '' && navbarColor !== 'light' && navbarColor !== 'white'

  return (
    <Navbar
      expand='lg'
      container={false}
      light={skin !== 'dark'}
      dark={skin === 'dark' || bgColorCondition}
      color={bgColorCondition ? navbarColor : undefined}
      className='header-navbar navbar align-items-center fixed-top navbar-shadow'
    >
      <div className='navbar-container d-flex content'>
        <div className='bookmark-wrapper d-flex align-items-center'>
          <ul className='navbar-nav'>
            <NavItem className='mobile-menu me-auto'>
              <NavLink className='nav-menu-main menu-toggle hidden-xs is-active' onClick={() => {
                closeSidebar()
                setMenuVisibility(true)
              }}>
                <Menu className='ficon' />
              </NavLink>
            </NavItem>
          </ul>
        </div>

        <div className='mx-auto'>
          <ul className='nav navbar-nav'>
            <NavItem>
              <Link to='/' className='navbar-brand'>
                <span className='brand-logo'>
                  <img src={skin === 'dark' ? logoFullWhite : logo} alt='logo' />
                </span>
              </Link>
            </NavItem>
          </ul>
        </div>

        <ul className='nav navbar-nav align-items-center'>
          <NotificationDropdown />
        </ul>
      </div>
    </Navbar>
  )
}

export default VerticalNavbar
