export const ResourceType = {
  ONE_TIME_PAYMENT: 0,
  FIXED_RECURRING_PLAN: 1,
  FIXED_RECURRING_SUBSCRIPTION: 2,
  VARIABLE_RECURRING_PLAN: 3,
  VARIABLE_RECURRING_SUBSCRIPTION: 4,
  ON_DEMAND_PLAN: 5,
  ON_DEMAND_SUBSCRIPTION: 6,
  SCHEDULE: 7,
  WALLET_TO_WALLET_PAYMENT: 8,
  STRATEGY: 9
}
