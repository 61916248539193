export default {
  storageKeys: {
    accessToken: 'accessToken',
    accessTokenExpiresAt: 'accessTokenExpiresAt',
    accessTokenIssuedAt: 'accessTokenIssuedAt',
    chain: 'chain',
    connector: 'connector',
    user: 'user'
  }
}
