// ** React Imports
import { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'

import { Button } from 'reactstrap'

// ** Custom Components
import UserInfo from '../common/UserInfo'

const VerticalMenuHeader = () => {
  const navigate = useNavigate()

  return (
    <Fragment>
      <div className='navbar-header mt-1'>
        <UserInfo />
      </div>

      <div className='mt-2 px-2'>
        <Button.Ripple className='round' color='primary' outline block onClick={() => navigate('/tiers')} >
          Upgrade Tier
        </Button.Ripple>
      </div>
    </Fragment>
  )
}

export default VerticalMenuHeader
