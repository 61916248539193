import { Copy } from 'react-feather'
import toast from 'react-hot-toast'
import ReactCopyToClipboard from 'react-copy-to-clipboard'

const onCopy = () => toast.success('Copied to clipboard!')

const CopyToClipboard = ({ text, size, className }) => {
  return (
    <ReactCopyToClipboard className={`${className} cursor-pointer`} text={text} onCopy={onCopy}>
      <Copy size={size || 16} onClick={e => e.stopPropagation()} />
    </ReactCopyToClipboard>
  )
}

export default CopyToClipboard
