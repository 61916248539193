import { useState, useCallback, useEffect, useMemo } from 'react'
import axios from 'axios'
import { readContract, fetchFeeData } from '@wagmi/core'
import BigNumber from 'bignumber.js'
import useApiUrlBuilder from './useApiUrlBuilder'
import { claimsEndpoints } from '../../constants/endpoints'
import useAuth from './useAuth'
import useTokens from './useTokens'
import environment from '../../configs/environments'
import EIGHT_PAY_TOKEN_V2_CLAIMS_ABI from '../../constants/abi/eightPayTokenV2Claims.json'

const useTokenClaims = () => {
  const { user, chain } = useAuth()
  const [contract, setContract] = useState()
  const [isReady, setIsReady] = useState(false)
  const [amount, setAmount] = useState()
  const [claimed, setClaimed] = useState()
  const [claimRequestedAt, setClaimRequestedAt] = useState(0)
  const buildApiUrl = useApiUrlBuilder()
  const tokens = useTokens()

  const getClaim = useCallback((chainId, address) => {
    return axios.get(buildApiUrl(claimsEndpoints.resource, { chainId, address }))
  }, [buildApiUrl])

  const claim = useCallback(async () => {
    const gasLimit = await readContract({
      address: contract.address,
      abi: contract.abi,
      functionName: 'gasLimit',
      args: []
    })

    const { gasPrice } = await fetchFeeData()

    return {
      address: contract.address,
      abi: contract.abi,
      functionName: 'claim',
      args: [],
      from: user,
      value: new BigNumber(gasLimit).multipliedBy(gasPrice).multipliedBy(6).toString()
    }
  }, [user, contract])

  const hasClaimed = useCallback(async account => {
    return readContract({
      address: contract.address,
      abi: contract.abi,
      functionName: 'claimed',
      args: [account]
    })
  }, [contract])

  const onClaimRequested = useCallback(() => {
    const now = Math.floor(Date.now() / 1000)
    localStorage.setItem('claimRequestedAt', now)
    setClaimRequestedAt(now)
  }, [])

  useEffect(() => {
    if (chain) {
      setContract({ address: environment.chains[chain].contracts.eightPayTokenV2Claims, abi: EIGHT_PAY_TOKEN_V2_CLAIMS_ABI })
      setIsReady(true)
    }
  }, [chain])

  useEffect(() => {
    if (isReady && user && chain) {
      const chainId = parseInt(environment.chains[chain]?.config.chainId)

      getClaim(chainId, user)
        .then(response => {
          setAmount(new BigNumber(tokens.formatAmount(response.data.amount, '8PAY')).toFixed(0))
        })

      hasClaimed(user)
        .then(result => {
          setClaimed(result)
        })

      setClaimRequestedAt(parseInt(localStorage.getItem('claimRequestedAt') ?? 0))
    }
  }, [isReady, getClaim, hasClaimed, user, chain, tokens])

  const tokenClaims = useMemo(() => ({
    isReady: isReady,
    amount: amount,
    canClaim: claimed === undefined || amount === undefined ? undefined : claimed === false && amount && amount !== '0' && (claimRequestedAt + 300 < Math.floor(Date.now() / 1000)),
    claimRequestedAt: claimRequestedAt,
    claim: claim,
    onClaimRequested: onClaimRequested
  }), [isReady, claim, amount, claimed, claimRequestedAt, onClaimRequested])

  return tokenClaims
}

export default useTokenClaims
