import { lazy } from 'react'

const Streaming = lazy(() => import('../../views/streaming'))
const ChannelDonations = lazy(() => import('../../views/streaming/ChannelDonations'))

const StreamingRoutes = [
  {
    path: '/streaming',
    element: <Streaming />
  },
  {
    path: '/streaming/:channelId',
    element: <ChannelDonations />
  }
]

export default StreamingRoutes
