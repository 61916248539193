// ** React Imports
import { Fragment, useEffect, memo } from 'react'
import { Link } from 'react-router-dom'

// ** Third Party Components
import classnames from 'classnames'

// ** Store & Actions
import { useSelector, useDispatch } from 'react-redux'
import { handleContentWidth, handleMenuCollapsed, handleMenuHidden } from '@store/layout'

// ** ThemeConfig
import themeConfig from '@configs/themeConfig'
import useTokenClaims from '@hooks/useTokenClaims'
import environment from '@src/configs/environments'

// ** Styles
import 'animate.css/animate.css'

const LayoutWrapper = props => {
  // ** Props
  const { children, routeMeta } = props

  // ** Store Vars
  const dispatch = useDispatch()
  const store = useSelector(state => state)
  const { canClaim } = useTokenClaims()

  const navbarStore = store.navbar
  const layoutStored = store.layout.layout
  const contentWidth = store.layout.contentWidth
  //** Vars
  const appLayoutCondition =
    (layoutStored.layout === 'horizontal' && !routeMeta) ||
    (layoutStored.layout === 'horizontal' && routeMeta && !routeMeta.appLayout)
  const Tag = appLayoutCondition ? 'div' : Fragment

  // ** Clean Up Function
  const cleanUp = () => {
    if (routeMeta) {
      if (routeMeta.contentWidth && routeMeta.contentWidth === store.layout.contentWidth) {
        dispatch(handleContentWidth(themeConfig.layout.contentWidth))
      }
      if (routeMeta.menuCollapsed && routeMeta.menuCollapsed === store.layout.menuCollapsed) {
        dispatch(handleMenuCollapsed(!store.layout.menuCollapsed))
      }
      if (routeMeta.menuHidden && routeMeta.menuHidden === store.layout.menuHidden) {
        dispatch(handleMenuHidden(!store.layout.menuHidden))
      }
    }
  }

  // ** ComponentDidMount
  useEffect(() => {
    if (routeMeta) {
      if (routeMeta.contentWidth) {
        dispatch(handleContentWidth(routeMeta.contentWidth))
      }
      if (routeMeta.menuCollapsed) {
        dispatch(handleMenuCollapsed(routeMeta.menuCollapsed))
      }
      if (routeMeta.menuHidden) {
        dispatch(handleMenuHidden(routeMeta.menuHidden))
      }
    }
    return () => cleanUp()
  }, [routeMeta])

  return (
    <div>
      {canClaim && (
        <div className='sticky-header'>
          <div className='d-flex bg-white'>
            <div className='bg-light-danger w-100 py-75 px-2 fw-bold text-center'>
              The migration to 8PAY v2 is currently under maintenance
            </div>
          </div>
        </div>
      )}

      <div
        id='content'
        className={classnames('app-content content overflow-hidden', {
          [routeMeta ? routeMeta.className : '']: routeMeta && routeMeta.className,
          'show-overlay': navbarStore.query.length,
          'mobile-content': environment.isMobileBuild
        })}
      >
        <div className='content-overlay'></div>
        <div className='header-navbar-shadow' />
        <div
          className={classnames({
            'content-wrapper': routeMeta && !routeMeta.appLayout,
            'content-area-wrapper': routeMeta && routeMeta.appLayout,
            'container-xxl p-0': contentWidth === 'boxed'
          })}
        >
          <Tag {...(appLayoutCondition ? { className: 'content-body' } : {})}>{children}</Tag>
        </div>
      </div>
    </div>
  )
}

export default memo(LayoutWrapper)
