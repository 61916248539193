import { useState, useCallback, useEffect } from 'react'
import { Button, Spinner } from 'reactstrap'
import { usePrepareContractWrite, useContractWrite } from 'wagmi'
import  { waitForTransaction } from '@wagmi/core'
import useAuth from '@hooks/useAuth'
import useWebsocket from '@hooks/useWebsocket'
import useTransactions from '../../utility/hooks/useTransactions'
import etherscanImg from '@src/assets/images/icons/etherscan.svg'
import environment from '../../configs/environments'

import './transaction-sender.scss'

const TransactionSender  = ({ transaction, enableWebsocket, description, confirmButtonColor = 'primary', onResult, onCancel }) => {
  const { chain } = useAuth()
  const transactions = useTransactions()
  const { websocket } = useWebsocket()
  const [status, setStatus] = useState(null)
  const [transactionHash, setTransactionHash] = useState()
  const [isSigning, setIsSigning] = useState(false)
  const [error, setError] = useState()

  const { config, error: prepareError } = usePrepareContractWrite(transaction)
  const { data, write, error: writeError } = useContractWrite(config)

  const getStatusFromBlockchain = useCallback(async () => {
    const receipt = await waitForTransaction({ hash: transactionHash })
    return receipt.status
  }, [transactionHash])

  const getStatusFromWebsocket = useCallback(() => {
    return new Promise(resolve => {
      let found = false

      // Create websocket listener
      const listener = hash => {
        if (transactionHash === hash) {
          found = true
          websocket.off('transaction.confirmed', listener)
          resolve(true)
        }
      }

      // Websocket messages can get lost so run a timer every 20 sec to double check
      const interval = setInterval(() => {
        if (found) {
          clearInterval(interval)
          return
        }

        // Get the transaction from the api
        transactions.api.getTransaction(transactionHash)
          .then(transaction => {
            websocket.off('transaction.confirmed', listener)
            clearInterval(interval)
            resolve(transaction.status !== 'failed')
          })
          .catch(() => {
            // If the api doesn't have it, check if it failed
            waitForTransaction({ hash: transactionHash, timeout: 5000 })
              .then(receipt => {
                if (receipt.status === false) {
                  websocket.off('transaction.confirmed', listener)
                  clearInterval(interval)
                  resolve(false)
                }
              })
          })
      }, 20000)

      // Listen for the transaction over websocket
      websocket.on('transaction.confirmed', listener)
    })
  }, [websocket, transactions, transactionHash])

  const openExplorer = () => window.open(`${environment.chains[chain].config.blockExplorerUrls[0]}/tx/${transactionHash}`)

  const sendTransaction = async () => {
    setIsSigning(true)
    write()
  }

  useEffect(() => {
    if (data) {
      setTransactionHash(data.hash)
    }
  }, [data])

  useEffect(() => {
    if (prepareError) {
      setError('An unexpected error occurred while preparing the transaction, please try again later.')
    }
  }, [prepareError])

  useEffect(() => {
    if (writeError) {
      if (writeError.cause.name === 'UserRejectedRequestError') {
        onCancel()
      } else {
        setError('The transaction was rejected or an unexpected error occurred while sending it, please try again.')
        setIsSigning(false)
        console.log(writeError)
      }
    }
  }, [writeError, onCancel])

  useEffect(() => {
    if (transactionHash) {
      setStatus(null)

      const promise = enableWebsocket ? getStatusFromWebsocket(transactionHash) : getStatusFromBlockchain(transactionHash)

      promise.then(result => {
        setStatus(result)
        setTimeout(() => {
          setTransactionHash(null)
          onResult(result)
        }, 5000)
      })
    }
  }, [transactionHash, enableWebsocket, onResult, getStatusFromBlockchain, getStatusFromWebsocket])

  useEffect(() => {
    return () => {
      if (status === null) {
        onResult(null)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      {!transactionHash && (!isSigning || environment.isMobileBuild) && !error && (
        <div>
          <div className='mb-2'>{description}</div>

          <div className='text-end'>
            <Button color='light' onClick={onCancel}>Cancel</Button>
            <Button className='ms-50' color={confirmButtonColor} onClick={sendTransaction} disabled={!write}>
              Confirm
            </Button>
          </div>
        </div>
      )}

      {!transactionHash && isSigning && !environment.isMobileBuild && (
        <div>
          <div className='d-flex align-items-center'>
            Please confirm the transaction on your wallet
          </div>

          <div className='animations d-flex justify-content-center mt-2'>
            <Spinner className='loading my-1' type='border' color='primary' />
          </div>
        </div>
      )}

      {transactionHash && (
        <div>
          <div className='d-flex flex-column flex-md-row'>
            <div className='me-1'>Transaction hash:</div>
            <div className='d-flex mt-25 mt-md-0'>
              <div>{transactionHash.slice(0, 8)}...{transactionHash.slice(-12)}</div>
              <img className='ms-1 cursor-pointer' src={etherscanImg} alt='explorer' onClick={openExplorer}/>
            </div>
          </div>

          <div className='animations d-flex justify-content-center mt-2'>
            {status === null && <Spinner className='loading my-1' type='border' color='primary' />}

            {status && (
              <div className="success">
                <svg id="successAnimation" className="animated" width="70" height="70"
                  viewBox="0 0 70 70">
                  <path id="successAnimationResult" fill="#D8D8D8"
                    d="M35,60 C21.1928813,60 10,48.8071187 10,35 C10,21.1928813 21.1928813,10 35,10 C48.8071187,10 60,21.1928813 60,35 C60,48.8071187 48.8071187,60 35,60 Z M23.6332378,33.2260427 L22.3667622,34.7739573 L34.1433655,44.40936 L47.776114,27.6305926 L46.223886,26.3694074 L33.8566345,41.59064 L23.6332378,33.2260427 Z" />
                  <circle id="successAnimationCircle" cx="35" cy="35" r="24" stroke="#979797" strokeWidth="2"
                    strokeLinecap="round" fill="transparent" />
                  <polyline id="successAnimationCheck" stroke="#979797" strokeWidth="2" points="23 34 34 43 47 27"
                    fill="transparent" />
                </svg>
              </div>
            )}

            {status === false && (
              <div className="fail">
                <svg version="1.1" id="failAnimation" width="70" height="70" viewBox="0 0 70 70"
                  style={{ enableBackground: 'new 0 0 70 70' }}>
                  <circle style={{ fill: '#fb567c' }} cx="25" cy="25" r="25" />
                  <polyline style={{ fill: 'none', stroke: '#FFFFFF', strokeWidth: 2, strokeLinecap: 'round', strokeMiterlimit: 10 }}
                    points="16,34 25,25 34,16" />
                  <polyline style={{ fill: 'none', stroke: '#FFFFFF', strokeWidth: 2, strokeLinecap: 'round', strokeMiterlimit: 10 }}
                    points="16,16 25,25 34,34" />
                </svg>
              </div>
            )}
          </div>
        </div>
      )}

      {error && (
        <div>
          <div className='mb-2'>{error}</div>

          <div className='text-end'>
            <Button color='light' onClick={onCancel}>Close</Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default TransactionSender
