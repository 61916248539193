// ** React Imports
import { lazy } from 'react'

const Shopify = lazy(() => import('../../views/shopify'))

const ShopifyRoutes = [
  {
    element: <Shopify />,
    path: '/shopify'
  }
]

export default ShopifyRoutes
