import { lazy } from 'react'

const Wallet = lazy(() => import('../../views/wallet'))

const WalletRoutes = [
  {
    path: '/wallet',
    element: <Wallet />
  }
]

export default WalletRoutes
