// ** React Imports
import { useState, useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

// ** Store & Actions
import { useSelector, useDispatch } from 'react-redux'
import { handleMenuCollapsed } from '@store/layout'

// ** Third Party Components
import classnames from 'classnames'

// ** Custom Components
import SidebarComponent from './components/vertical/Sidebar'
import VerticalNavbar from './components/vertical/VerticalNavbar'
import MobileNavigation from './components/mobile/MobileNavigation'

// ** Custom Hooks
import { useSkin } from '@hooks/useSkin'

// ** Menu Items Array
import navigation from '@src/navigation/vertical'

import environment from '@src/configs/environments'

// ** Styles
import '@styles/base/core/menu/menu-types/vertical-menu.scss'
import '@styles/base/core/menu/menu-types/vertical-overlay-menu.scss'

const VerticalLayout = () => {
  // ** Hooks
  const { skin } = useSkin()

  // ** States
  const [isMounted, setIsMounted] = useState(false)
  const [menuVisibility, setMenuVisibility] = useState(false)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  // ** Vars
  const dispatch = useDispatch()
  const layoutStore = useSelector(state => state.layout)

  // ** Update Window Width
  const handleWindowWidth = () => {
    setWindowWidth(window.innerWidth)
  }

  // ** Vars
  const location = useLocation()
  const menuCollapsed = layoutStore.menuCollapsed
  const breakpoint = 992

  // ** Toggles Menu Collapsed
  const setMenuCollapsed = val => dispatch(handleMenuCollapsed(val))

  //** This function will detect the Route Change and will hide the menu on menu item click
  useEffect(() => {
    if (menuVisibility && windowWidth < breakpoint) {
      setMenuVisibility(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  //** Sets Window Size & Layout Props
  useEffect(() => {
    if (window !== undefined) {
      window.addEventListener('resize', handleWindowWidth)
    }
  }, [windowWidth])

  //** ComponentDidMount
  useEffect(() => {
    setIsMounted(true)
    return () => setIsMounted(false)
  }, [])

  if (!isMounted) {
    return null
  }

  return (
    <div
      className={classnames(`wrapper vertical-layout navbar-sticky`,
        {
          // Modern Menu
          'vertical-menu-modern': windowWidth >= breakpoint,
          'menu-collapsed': menuCollapsed && windowWidth >= breakpoint,
          'menu-expanded': !menuCollapsed && windowWidth > breakpoint,

          // Overlay Menu
          'vertical-overlay-menu': windowWidth < breakpoint,
          'menu-hide': !menuVisibility && windowWidth < breakpoint,
          'menu-open': menuVisibility && windowWidth < breakpoint
        }
      )}
    >
      <SidebarComponent
        skin={skin}
        menuData={navigation}
        menuCollapsed={menuCollapsed}
        menuVisibility={menuVisibility}
        setMenuCollapsed={setMenuCollapsed}
        setMenuVisibility={setMenuVisibility}
      />

      <VerticalNavbar menuVisibility={menuVisibility} setMenuVisibility={setMenuVisibility}/>

      <Outlet />

      {environment.isMobileBuild && <MobileNavigation />}

      <div
        className={classnames('sidenav-overlay', { show: menuVisibility })}
        onClick={() => setMenuVisibility(false)}
      ></div>
    </div>
  )
}

export default VerticalLayout
