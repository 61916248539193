import { useCallback } from 'react'
import useModal from './useModal'
import TransactionSender from '../../components/transaction-sender'

class Wrapper {
  constructor(promise) {
    promise
      .then(result => {
        if (result) {
          this.successCb?.()
        } else if (result === false) {
          this.failCb?.()
        } else {
          this.cancelCb?.()
        }
      })
  }

  onSuccess(cb) {
    this.successCb = cb
    return this
  }

  onFail(cb) {
    this.failCb = cb
    return this
  }

  onCancel(cb) {
    this.cancelCb = cb
    return this
  }
}

const useTransactionSender = () => {
  const { openModal, closeModal } = useModal()

  const openTransactionSender = useCallback((data) => {
    return new Wrapper(new Promise(resolve => {
      openModal({
        title: data.title,
        description: (
          <TransactionSender
            transaction={data.transaction}
            description={data.description}
            enableWebsocket={data.websocket}
            confirmButtonColor={data.confirmButtonColor}
            onResult={result => { closeModal(); resolve(result) }}
            onCancel={closeModal}
          />
        )
      })
    }))
  }, [openModal, closeModal])

  return { openTransactionSender }
}

export default useTransactionSender
