import { useCallback, useMemo, useState, useEffect } from 'react'
import { Button } from 'reactstrap'
import CopyToClipboard from '../copy-to-clipboard'
import BigNumber from 'bignumber.js'
import useAuth from '../../utility/hooks/useAuth'
import useScheduledPayments from '../../utility/hooks/useScheduledPayments'
import useTransactionSender from '../../utility/hooks/useTransactionSender'
import ScheduleStatusBadge from '../schedule-status-badge'
import { formatDateTime, formatTokenAmount, getExplorerUrl, getNetAmount, intervalToText, shortHash } from '../../utility/Utils'
import { ScheduledPaymentStatus } from '../../utility/Filters'

const ScheduledPayment = ({ scheduleId, onScheduleUpdated }) => {
  const { chain, user } = useAuth()
  const scheduledPayments = useScheduledPayments()
  const { openTransactionSender } = useTransactionSender()

  const [schedule, setSchedule] = useState()
  const [executions, setExecutions] = useState([])

  const executionsWithNetAmount = useMemo(() => {
    if (!schedule) {
      return []
    }

    return executions.map(e => {
      const receivers = schedule.admin === user ? e.receivers : e.receivers.filter(e => e.user === user)
      const netAmount = receivers.reduce((tot, e) => tot.plus(schedule.admin === user ? e.amount : getNetAmount(e.amount, e.fee)), new BigNumber(0)).toFixed()
      return { ...e, netAmount }
    })
  }, [executions, schedule, user])

  const cancelSchedule = useCallback(() => {
    openTransactionSender({
      title: 'Cancel Scheduled Payment',
      description: `If you confirm and cancel your scheduled payment "${schedule.name}" now, no executions will be performed and you won't be charged anymore.`,
      transaction: scheduledPayments.blockchain.cancelSchedule(schedule.id),
      confirmButtonColor: 'danger',
      websocket: true
    }).onSuccess(() => {
      scheduledPayments.api.getSchedule(scheduleId)
        .then(res => {
          setSchedule(res.data)
          onScheduleUpdated(res.data)
        })
    })
  }, [onScheduleUpdated, schedule, scheduleId, scheduledPayments, openTransactionSender])

  useEffect(() => {
    scheduledPayments.api.getSchedule(scheduleId).then(res => setSchedule(res.data))
    scheduledPayments.api.listScheduleExecutions(scheduleId).then(res => setExecutions(res.data.data))
  }, [scheduleId, scheduledPayments])

  if (!schedule) {
    return
  }

  return (
    <div className='d-flex flex-column h-100'>
      <div className='d-flex align-items-center justify-content-between mb-1'>
        <div>ID</div>
        <div className='d-flex align-items-center justify-content-end fw-bold'>
          <div className='me-50'>{shortHash(schedule.id)}</div>
          <CopyToClipboard text={schedule.id} />
        </div>
      </div>

      <div className='d-flex align-items-center justify-content-between mb-1'>
        <div>Status</div>
        <div className='d-flex align-items-center justify-content-end fw-bold'>
          <ScheduleStatusBadge status={schedule.status}/>
        </div>
      </div>

      <div className='d-flex align-items-center justify-content-between mb-1'>
        <div>Name</div>
        <div className='d-flex align-items-center justify-content-end fw-bold'>
          {schedule.name}
        </div>
      </div>

      <div className='d-flex align-items-center justify-content-between mb-1'>
        <div>Frequency</div>
        <div className='d-flex align-items-center justify-content-end fw-bold'>
          {schedule.repeat === 0 ? 'Once' : `${intervalToText(schedule.repeat)}`}
        </div>
      </div>

      <div className='d-flex align-items-center justify-content-between mb-1'>
        <div>Next Execution</div>
        <div className='d-flex align-items-center justify-content-end fw-bold'>
          {schedule.nextExecution ? formatDateTime(schedule.nextExecution * 1000) : 'None'}
        </div>
      </div>

      <div className='d-flex align-items-center justify-content-between mb-1'>
        <div>Started On</div>
        <div className='d-flex align-items-center justify-content-end fw-bold'>
          {formatDateTime(schedule.start * 1000)}
        </div>
      </div>

      <div className='d-flex align-items-center justify-content-between mb-1'>
        <div>Transaction Hash</div>
        <div className='d-flex align-items-center justify-content-end fw-bold'>
          <a className='text-decoration-underline me-50' href={getExplorerUrl(chain, schedule.transactionHash)} target='_blank'>
            {shortHash(schedule.transactionHash)}
          </a>
          <CopyToClipboard text={schedule.transactionHash} />
        </div>
      </div>

      {schedule.admin === user && (
        <div className='my-3'>
          <h4 className='mb-1'>Receivers</h4>
          <table className='w-100'>
            <thead>
              <tr>
                <th>User</th>
                <th className='text-end'>Amount</th>
              </tr>
            </thead>
            <tbody>
              {schedule.receivers.map(e => (
                <tr key={e.user}>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='me-50'>{shortHash(e.user)}</div>
                      <CopyToClipboard text={e.user} />
                    </div>
                  </td>
                  <td className='text-end'>{formatTokenAmount(e.amount)} {schedule.token}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {!!executionsWithNetAmount.length && (
        <div className='my-3'>
          <h4 className='mb-1'>Executions</h4>
          <table className='w-100'>
            <thead>
              <tr>
                <th>Date</th>
                <th className='text-end'>Amount</th>
                <th className='text-end'>Transaction Hash</th>
              </tr>
            </thead>
            <tbody>
              {executionsWithNetAmount.map(e => (
                <tr key={e.transactionHash}>
                  <td>{formatDateTime(e.timestamp * 1000)}</td>
                  <td className='text-end'>{formatTokenAmount(e.netAmount)} {schedule.token}</td>
                  <td className='d-flex align-items-center justify-content-end'>
                    <a className='text-decoration-underline me-50' href={getExplorerUrl(chain, e.transactionHash)} target='_blank'>
                      {shortHash(e.transactionHash)}
                    </a>
                    <CopyToClipboard text={e.transactionHash} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {schedule.admin === user && schedule.status === ScheduledPaymentStatus.ACTIVE && (
        <div className='mt-auto pb-2'>
          <Button.Ripple color='danger' block onClick={cancelSchedule}>Cancel</Button.Ripple>
        </div>
      )}
    </div>
  )

}

export default ScheduledPayment
