// ** React Imports
import { Outlet } from 'react-router-dom'
import { useState, useEffect } from 'react'

// ** Custom Components
import HorizontalNavbar from './components/horizontal/HorizontalNavbar'

// ** Styles
import '@styles/base/core/menu/menu-types/horizontal-menu.scss'

const HorizontalLayout = () => {
  // ** States
  const [isMounted, setIsMounted] = useState(false)

  // ** UseEffect Cleanup
  const cleanup = () => {
    setIsMounted(false)
  }

  //** ComponentDidMount
  useEffect(() => {
    setIsMounted(true)

    return () => cleanup()
  }, [])

  if (!isMounted) {
    return null
  }

  return (
    <div className='wrapper horizontal-layout horizontal-menu navbar-sticky menu-expanded'>
      <div className='horizontal-menu-wrapper'>
        <HorizontalNavbar />
      </div>

      <Outlet />
    </div>
  )
}
export default HorizontalLayout
