import { useEffect, useState, createContext } from 'react'
import { io } from "socket.io-client"
import environment from '@src/configs/environments'
import useAuth from '@hooks/useAuth'

const Websocket = createContext()

const WebsocketProvider = ({ children }) => {
  const [websocket, setWebsocket] = useState(null)
  const [isReady, setIsReady] = useState(false)
  const { accessToken, chain, loggedIn } = useAuth()

  useEffect(() => {
    if (loggedIn && !websocket) {
      setWebsocket(io(environment.domains.websocket, {
        path: `/${chain}/socket.io`,
        transportOptions: {
          polling: {
            extraHeaders: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        }
      }))
    } else if (!loggedIn && websocket) {
      websocket.close()
      setWebsocket(null)
    }
  }, [websocket, loggedIn, chain, accessToken])

  useEffect(() => {
    if (websocket && !isReady) {
      websocket.on('connect', () => setIsReady(true))
    }
  }, [websocket, isReady])

  return <Websocket.Provider value={{ websocket, isReady }}>{children}</Websocket.Provider>
}

export { Websocket, WebsocketProvider }
