// ** React Imports
import { lazy } from 'react'

const ApiKeys = lazy(() => import('../../views/api-keys'))

const ApiKeysRoutes = [
  {
    element: <ApiKeys />,
    path: '/api-keys',
    meta: {
      publicRoute: true
    }
  }
]

export default ApiKeysRoutes
