import React, { useState } from 'react'
import { useZxing } from "react-zxing"

import './qr-reader.scss'

export const Qrscan = ({ onResult }) => {
  const [permissionError, setPermissionError] = useState(false)

  const { ref } = useZxing({
    onDecodeResult(result) {
      onResult?.(result.getText())
    },
    onError(error) {
      if (error.message.includes('Permission denied') || error.name === 'NotAllowedError') {
        setPermissionError(true)
      } else {
        console.log(error)
      }
    }
  })

  return (
    <div className='mt-3'>
      <video className='w-100' ref={ref} />
      {permissionError && <div className='text-center mx-2'>Please allow access to the camera to scan QR codes.</div>}
    </div>
  )
}

export default Qrscan
