import { useCallback, useEffect, useMemo, useState } from 'react'
import axios from 'axios'
import { BigNumber } from 'bignumber.js'
import useAuth from './useAuth'
import useDeployments from './useDeployments'
import useApiUrlBuilder from './useApiUrlBuilder'
import { oneTimeEndpoints } from '../../constants/endpoints'
import { NATIVE_TOKEN_ADDRESS } from '../../constants/addresses'
import { toHex, pad } from 'viem'

const useOneTime = () => {
  const { account } = useAuth()
  const deployments = useDeployments()
  const buildApiUrl = useApiUrlBuilder()

  const [contract, setContract] = useState()

  useEffect(() => {
    if (deployments) {
      setContract(deployments.get('OneTime'))
    }
  }, [deployments])

  // ** Api

  const getPayment = useCallback(id => {
    return axios.get(buildApiUrl(oneTimeEndpoints.payments.resource, { id }))
  }, [buildApiUrl])

  const listTransfers = useCallback(params => {
    return axios.get(buildApiUrl(oneTimeEndpoints.transfers.collection), { params })
  }, [buildApiUrl])

  const createShortUrl = useCallback(data => {
    return axios.post(buildApiUrl(oneTimeEndpoints.shortUrls.collection), data)
  }, [buildApiUrl])

  // ** Blockchain

  const send = useCallback((description, token, receivers, amounts, category, tag) => ({
    address: contract.address,
    abi: contract.abi,
    functionName: 'send',
    args: [description, token, receivers, amounts, category, pad(toHex(tag), { dir: 'right' })],
    from: account,
    value: token === NATIVE_TOKEN_ADDRESS ? amounts.reduce((tot, amount) => tot.plus(amount), new BigNumber(0)).toString() : '0'
  }), [account, contract])

  const oneTime = useMemo(() => ({
    api: { createShortUrl, getPayment, listTransfers },
    blockchain: { send }
  }), [createShortUrl, getPayment, listTransfers, send])

  return oneTime
}

export default useOneTime
